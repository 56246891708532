import React, { useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import {
    UsersForm, FormControl, FormTitle, NameSurname, ModalAddButtonSalon,
    ModalDarkBackGround, ModalStyle, ModalCloseButton, SelectOptionBox,
    InputProfileForm, DivForLabelAndInputUser, ModalCloseButtonMobile, CloseButton,
    LabelProfile, SelectOption, DivForLabelAndInput, NameFirst, ModalStyleMobile, ModalAddButtonService, CloseButtonMobile
} from "../../styles/MainLayout"
import { useParams } from "react-router-dom";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { useEffect } from "react";

const EditSalonModal = (props) => {
    const [salon, setSalon] = useState([])
    const [users, setUser] = useState({});
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    const [membership, setMembership] = useState([])
    const [apptStatus, setApptStatus] = useState(salon?.appt_def_status)
    const [status, setStatus] = useState([])


    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    const { sid } = useParams();
    console.log("propsServices", props)

    ///AXIOS

    //axios

    //AXIOS
    const getMembership = () => {
        axios
            .get("/api/store/subscriptions/all")
            .then((response) => {
                console.log("olaLabels", response.data);
                setMembership(response.data)

            }).catch(err => {
                console.log(err)
            })
    }
    const getStatus = () => {
        axios
            .get("/api/store/appointments_statuses/all")
            .then((response) => {
                console.log("olaLabels", response.data);
                setStatus(response.data)

            }).catch(err => {
                console.log(err)
            })
    }
    const getSalon = () => {
        axios.get(`api/store/salons/single/${props?.id}`).then(
            data => {
                setSalon(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }


    //AXIOS

    useEffect(() => {
        getSalon()
        getMembership()
        getStatus()
    }, [])

    //AXIOS
    const [editSalonName, setEditSalonName] = useState()
    const [editSalonDesc, setEditSalonDesc] = useState()
    const [shouldEdit, setshouldEdit] = useState(true)
    const SalonEdit = (e) => {

        let body = {
            id: props?.id,
            name_al: editSalonName,
            name_en: editSalonName,
            desc_al: editSalonDesc,
            desc_en: editSalonDesc,
            appt_def_status: Number(apptStatus),
        }
        console.log("body", body)
        axios.put(`/api/store/salons/update`, body)
            .then((data) => {
                props?.handleModalClose();
                console.log(data.data)
                toast.success("Salon edited sucessfully")
                setshouldEdit(true)
            }).catch(
                err => {
                    toast.error(err)
                    setshouldEdit(true)
                    console.log(err)
                }
            )
    }

    if (props?.isOpen) {
        if (viewportSize > 800) {
            //----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                            <CloseButton>
                                <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Edit Business</FormTitle>
                                <hr />
                                <UsersForm onSubmit={(e) => { e.preventDefault(); setshouldEdit(false); SalonEdit() }}>
                                    <FormControl>
                                        <NameSurname>
                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile >Emri i Salonit</LabelProfile>
                                                <InputProfileForm defaultValue={salon?.name_al} onChange={(e) => setEditSalonName(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                            </DivForLabelAndInputUser>
                                        </NameSurname>
                                        {/* <NameSurname> */}

                                        {/* </NameSurname> */}
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Description </LabelProfile>
                                            <textarea defaultValue={salon?.desc_al} onChange={(e) => setEditSalonDesc(e.target.value)} className="form-control" cols="30" rows="3" placeholder="Description..." required id="desc_al" name="desc_al"></textarea>
                                        </DivForLabelAndInputUser>
                                        <SelectOptionBox className="gap-3 d-flex mt-2">
                                            <DivForLabelAndInputUser className="forDisable">
                                                Zgjedhni statusin automatik te terminit :
                                                <select className="form-select" onChange={(e) => setApptStatus(e.target.value)}>
                                                    <option hidden value={salon?.appt_def_status}>{status?.find(el => el?.id == salon?.appt_def_status)?.status_name}</option>
                                                    {status?.filter(el => el.id != 6 && el.id != 1 && el.id != 2 && el.id != 3)?.map(el => {
                                                        return (
                                                            <option value={el?.id}>{el?.status_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </DivForLabelAndInputUser>
                                        </SelectOptionBox>
                                        <SelectOptionBox className="gap-3 d-flex mt-2">
                                            <DivForLabelAndInputUser className="forDisable">
                                                Membership : {membership.find(el => el?.id == salon?.subscription_id)?.name}
                                            </DivForLabelAndInputUser>
                                        </SelectOptionBox>
                                        {/* <Surname>
                                                <DivForLabelAndInput className="forDisable">
                                                <LabelProfile> Service Name </LabelProfile>
                                                <InputProfileForm defaultValue={props.data.name_en} placeholder="Service name" type="text" name="name_en" />
                                                </DivForLabelAndInput>
                                            </Surname> */}
                                        <br />
                                        <div className="d-flex justify-content-end">

                                            <ModalAddButtonSalon disabled={shouldEdit == false ? true : ""} type={"submit"}>Edit</ModalAddButtonSalon>
                                        </div>
                                    </FormControl>
                                </UsersForm>
                            </>
                        </ModalStyle>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>

                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleMobile>
                        <CloseButtonMobile>

                            <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                        </CloseButtonMobile>
                        <>
                            <FormTitle className="d-flex justify-content-center mt-3">Edit Salon</FormTitle>
                            <hr />
                            <UsersForm onSubmit={(e) => { e.preventDefault(); setshouldEdit(false); SalonEdit() }}>
                                <FormControl>
                                    <NameSurname>
                                        <NameFirst>
                                            <DivForLabelAndInput className="forDisable">
                                                <LabelProfile >Emri i Salonit</LabelProfile>
                                                <InputProfileForm defaultValue={salon.name_al} onChange={(e) => setEditSalonName(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                            </DivForLabelAndInput>
                                        </NameFirst>
                                        {/* <Surname>
                                            <Label> Service Name </Label>
                                            <Input placeholder="Service name" type="text" name="name_en" />
                                        </Surname> */}
                                    </NameSurname>


                                    {/* </NameSurname> */}
                                    <DivForLabelAndInputUser className="forDisable">
                                        <LabelProfile>Description </LabelProfile>
                                        <textarea defaultValue={salon.desc_al} onChange={(e) => setEditSalonDesc(e.target.value)} className="form-control" cols="30" rows="3" placeholder="Description..." required id="desc_al" name="desc_al"></textarea>
                                    </DivForLabelAndInputUser>
                                    <SelectOptionBox className="gap-3 d-flex mt-2">
                                        <DivForLabelAndInputUser className="forDisable">
                                            Zgjedhni statusin automatik te terminit :
                                            <select className="form-select" onChange={(e) => setApptStatus(e.target.value)}>
                                                <option hidden value={salon?.appt_def_status}>{status?.find(el => el?.id == salon?.appt_def_status)?.status_name}</option>
                                                {status?.filter(el => el.id != 6 && el.id != 1 && el.id != 2 && el.id != 3)?.map(el => {
                                                    return (
                                                        <option value={el?.id}>{el?.status_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </DivForLabelAndInputUser>
                                    </SelectOptionBox>
                                    <SelectOptionBox className="gap-3 d-flex mt-2">
                                        <DivForLabelAndInputUser className="forDisable">
                                            Membership : {membership.find(el => el?.id == salon?.subscription_id)?.name}
                                        </DivForLabelAndInputUser>
                                    </SelectOptionBox>


                                    {/* <Surname>
                                                <DivForLabelAndInput className="forDisable">
                                                <LabelProfile> Service Name </LabelProfile>
                                                <InputProfileForm defaultValue={props.data.name_en} placeholder="Service name" type="text" name="name_en" />
                                                </DivForLabelAndInput>
                                            </Surname> */}
                                    <br />

                                    <br />
                                    <div className="d-flex justify-content-end">
                                        <ModalAddButtonSalon disabled={shouldEdit == false ? true : ""} type={"submit"}>Edit</ModalAddButtonSalon>
                                    </div>
                                </FormControl>
                            </UsersForm>
                        </>
                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}

export default EditSalonModal
