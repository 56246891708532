import { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/4.png";
import terminilogo from "../../assets/images/logo-termini.png";
import "./sidebar.scss";
import { connect } from "react-redux";
import { setSideBar, setSelectedLang } from "../../redux/Functions/actions";
import { MenuItems } from "../../data/sidebarData";
import harrisia_icon from "../../assets/images/company-logo/harrisia-icons.png";
import harrisia_logo from "../../assets/images/company-logo/harrisia-logo-01.png";
import { CgCloseR } from "react-icons/cg"
import { TfiMenuAlt } from "react-icons/tfi"
import axios from "../../axios"
import ServiceModal from "../Modal/ServiceModal"
import en from "../../assets/images/enFlag.png"
import al from "../../assets/images/alFlag.png"

// import Data from "./Data.json"

const Sidebari = (props) => {


  const location = useLocation();

  const [icon, setIcon] = useState(props?.sidebar === "" ? false : true);
  const [services, setServices] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [modalType, setModalType] = useState(false)
  const { collapseSidebar } = useProSidebar();
  // const [languages, setLanguages] = useState([]);



  //AXIOS
  const getServices = () => {
    axios.get('/api/store/services/all').then(
      data => {
        console.log("serviset", data.data)
        setServices(data.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }



  const [viewportSize, setViewportSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });

  function handleOpenCollapse() {
    props.setSideBar("")
    setIcon(true)
    collapseSidebar(false);
  }
  function handleCloseCollapse() {
    props.setSideBar("collapsed")
    setIcon(false)
    collapseSidebar(true);
  }
  const handleModalAdd = () => {
    setIsOpen(true)
    setModalType("add")
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }


  useEffect(() => {
    getServices()
    if (props.sidebar === "")
      handleOpenCollapse()
    else
      handleCloseCollapse()
  }, [])

  const [selectedLang, setSelectedLang] = useState(props.selected_lang || '');
  const handleLangSelect = (lang) => {
    setSelectedLang(lang);
    props?.setSelectedLang(lang);
  };


  useEffect(() => {
    setSelectedLang(props.selected_lang);
  }, [props.selected_lang]);
  if (viewportSize > 800) {
    return (

      <div className="sidebar_layout">
        {isOpen === true ?
          <ServiceModal isOpen={isOpen} type={modalType} handleModalClose={handleModalClose} />
          :
          ""
        }
        <span
          className={`collapse_sidebar_button ${icon === true ? "" : "collapsed"
            }`}
          onClick={() => {
            props.sidebar === "collapsed"
              ?
              handleOpenCollapse()
              :
              handleCloseCollapse()
          }}
        >
          {icon === false ? (
            <TfiMenuAlt style={{ fontSize: '25px' }} role="button" />
          )
            :
            (
              <CgCloseR style={{ fontSize: '30px' }} role="button" />
            )}
        </span>
        <div className="sidebar-custom">
          <Sidebar defaultCollapsed={props?.Sidebar === "collapsed" ? true : false}>
            <Menu className="sidebarMenu"
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: disabled ? "black" : "black",
                      backgroundColor: active ? "rgb(255, 87, 88)" : undefined,
                      borderRadius: active ? "12px" : undefined,

                    };
                },
              }}
            >
              <MenuItem
                className="borderheader"
                style={icon === true ? { marginLeft: "-30px", height: '72px', paddingBottom: '30px', width: '275px' } : {}}
                icon={
                  icon === false ? (
                    <img
                      src={logo}
                      alt="logo"
                      className="logo-collapsed"
                    />
                  ) : (
                    ""
                  )
                }
                disabled
              >
                <div className="logo_layout">
                  {icon === true ? (
                    <>
                      <img
                        src={terminilogo}
                        alt="termini. logo"
                        className="termini-logo-text"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </MenuItem>
              <div class={props?.sidebar === "collapsed" ? "mt-2 dropdown w-50" : "mt-1 dropdown  "} style={props?.sidebar === "collapsed" ? null : { margin: '0 auto', marginRight:'5px' }}>
                <button type="button" data-bs-toggle="dropdown" aria-expanded="false" id="languageDropdown" class={props?.sidebar === "collapsed" ? "mt-4 dropdown btn btn-dark dropdown-toggle w-50" : "mt-2 w-100  dropdown btn btn-dark dropdown-toggle w-75"}>
                  {props?.sidebar === "collapsed" ? (
                    <img src={selectedLang === 'AL' ? al : en} alt={selectedLang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
                  ) : (
                    selectedLang ?
                      <span>
                        <img src={selectedLang === 'AL' ? al : en} alt={selectedLang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
                        {selectedLang === 'AL' ? 'Albania' : 'English'}
                      </span>
                      : 'Choose Language'
                  )}
                </button>
                <ul class="dropdown-menu w-75" aria-labelledby="languageDropdown">
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <a class="dropdown-item" href="#" onClick={() => handleLangSelect('EN')}>
                          <img src={en} alt="English Flag" style={{ maxWidth: '25px', maxHeight: '35px', marginRight: '5px', borderRadius: '3px' }} />
                        {props?.sidebar !== "collapsed" && "English" } 
                        </a>
                        <a class="dropdown-item" href="#" onClick={() => handleLangSelect('AL')}>
                          <img src={al} alt="Albanian Flag" style={{ maxWidth: '25px', maxHeight: '20px', marginRight: '5px', borderRadius: '3px' }} />
                          {props?.sidebar !== "collapsed" && "Albania"}
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <MenuItem
                style={{ marginLeft: "-15px" }}
                disabled
                className={icon === true ? "" : " m-0"}
              >
                <strong className="text-secondary">General</strong>
              </MenuItem>
              {MenuItems?.map((el, index) => {
                console.log('sidebar dajko', el)
                if (props?.user_data?.role == 4 && index == 0 ||
                  props?.user_data?.role == 4 && index == 2 ||
                  props?.user_data?.role == 4 && index == 3) {
                  return null;
                }
                return (
                  <>
                    <MenuItem
                      style={{ marginRight: '5px' }}
                      active={props?.user_data?.role == 4 && location.pathname == "/" ? "/" : location.pathname === el?.path}
                      component={<Link to={el?.menu_name == "Appointments" && props?.user_data?.role == 4 ? "/" : el?.path} />}
                    >
                      {icon === false ? (
                        <img src={el?.menu_icon} className="menu-icons-collapsed" alt="" />
                      ) : (
                        <span>
                          <img src={el?.menu_icon} className="menu-icons" alt="" />
                          <strong
                          >{el?.menu_name}</strong>
                        </span>
                      )}
                    </MenuItem>
                    {/* <SubMenu> */}

                    {el?.submenuContent?.map((elem) => {
                      return (
                        <MenuItem style={{ backgroundColor: "#d3d3d347" }}>
                          {elem}
                        </MenuItem>
                      );
                    })}
                    {/* </SubMenu> */}
                  </>
                );
              })}
              {/* <div style={icon === false ? { width: '75%' } : null}>
                <MenuItem
                  style={{ marginRight: '5px' }}
                  active={location.pathname === "/services"}
                  component={<Link to={"/services"} />}
                >
                  <AiOutlineScissor style={{ fontSize: '25px' }} /><strong>{props.sidebar === "collapsed" ? "" : "Services"}</strong>
                </MenuItem>
              </div> */}
            </Menu>

            <div
              className={`sidebar_footer_layout ${props?.sidebar === "collapsed" ? "collapsed" : ""
                }`}
            >
              {props?.sidebar === "collapsed" ? (
                <img
                  className="harrisia_icon img-fluid col-12"
                  src={harrisia_icon}
                  alt="harrisia_logo"
                />
              ) : (
                <>
                  <div className="poweredBy">
                    <span className="poweredBy-text">

                      Powered by{" "}

                      <img
                        src={harrisia_logo}
                        alt="harrisia"
                        className="harrisia_logo"
                      ></img>

                    </span>
                  </div>
                </>
              )}
            </div>

          </Sidebar>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className={`sidebar_layout`} id="sidebar_layout">
          <span
            className={location.pathname == "/appointments" && props?.appView == 0 ? `collapse_sidebar_button2 ${icon === true ? "" : "collapsed"}` : `collapse_sidebar_button ${icon === true ? "" : "collapsed"}`}
            onClick={() => {
              props.sidebar === "collapsed"
                ? props.setSideBar("")
                : props.setSideBar("collapsed");
              collapseSidebar();
              setIcon(!icon);
            }}
          >
            {icon === false ? (
              <TfiMenuAlt style={{ fontSize: '25px' }} role="button" />
            )
              :
              (
                <CgCloseR style={{ fontSize: '30px' }} role="button" />
              )}
          </span>
          <div className={location.pathname == "/appointments" && props?.appView == 0 ? `sidebar-custom2 ${icon === true ? "collapsed" : ""}` : "sidebar-custom"} id="sidebar-custom">
            <Sidebar>
              <Menu
                style={{
                  display: "flex",
                  backgroundColor: "white",
                  padding: "5px"
                }}
                menuItemStyles={{
                  button: ({ level, active, disabled }) => {
                    // only apply styles on first level elements of the tree
                    if (level === 0)
                      return {
                        color: disabled ? "black" : "black",
                        backgroundColor: active ? "rgb(255, 87, 88)" : undefined,
                        borderRadius: active ? "12px" : undefined,

                      };
                  },
                }}
              >
                <MenuItem
                  className="borderheader"
                  style={icon === true ? { marginLeft: "-30px", height: '72px', paddingBottom: '30px', width: '275px' } : {}}
                  icon={
                    icon === false ? (
                      <img
                        src={logo}
                        alt="logo"
                        className="logo-collapsed"
                      />
                    ) : (
                      ""
                    )
                  }
                  disabled
                >
                  <div className="logo_layout">
                    {icon === true ? (
                      <>
                        <img
                          src={terminilogo}
                          alt="termini. logo"
                          className="termini-logo-text"
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </MenuItem>
                <div class={props?.sidebar === "collapsed" ? "mt-2 dropdown w-50" : "mt-1 dropdown  "} style={props?.sidebar === "collapsed" ? null : { margin: '0 auto', marginRight:'5px' }}>
                <button type="button" data-bs-toggle="dropdown" aria-expanded="false" id="languageDropdown" class={props?.sidebar === "collapsed" ? "mt-4 dropdown btn btn-dark dropdown-toggle w-50" : "mt-2 w-100  dropdown btn btn-dark dropdown-toggle w-75"}>
                  {props?.sidebar === "collapsed" ? (
                    <img src={selectedLang === 'AL' ? al : en} alt={selectedLang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
                  ) : (
                    selectedLang ?
                      <span>
                        <img src={selectedLang === 'AL' ? al : en} alt={selectedLang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
                        {selectedLang === 'AL' ? 'Albania' : 'English'}
                      </span>
                      : 'Choose Language'
                  )}
                </button>
                <ul class="dropdown-menu w-75" aria-labelledby="languageDropdown">
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <a class="dropdown-item" href="#" onClick={() => handleLangSelect('EN')}>
                          <img src={en} alt="English Flag" style={{ maxWidth: '25px', maxHeight: '35px', marginRight: '5px', borderRadius: '3px' }} />
                        {props?.sidebar !== "collapsed" && "English" } 
                        </a>
                        <a class="dropdown-item" href="#" onClick={() => handleLangSelect('AL')}>
                          <img src={al} alt="Albanian Flag" style={{ maxWidth: '25px', maxHeight: '20px', marginRight: '5px', borderRadius: '3px' }} />
                          {props?.sidebar !== "collapsed" && "Albania"}
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

                <MenuItem
                  style={{ marginLeft: "-15px" }}
                  disabled
                  className={icon === true ? "mt-2" : "mt-2 m-0"}
                >
                  <strong className="text-secondary">General</strong>
                </MenuItem>
                {MenuItems?.map((el, index) => {
                  if (props?.user_data?.role == 4 && index == 0 ||
                    props?.user_data?.role == 4 && index == 2 ||
                    props?.user_data?.role == 4 && index == 3) {
                    return null;
                  }
                  return (
                    <>
                      <MenuItem
                        style={{ marginRight: '5px' }}
                        // active={location.pathname === el?.path}
                        // component={<Link to={el?.path} />}
                        active={props?.user_data?.role == 4 && location.pathname == "/" ? "/" : location.pathname === el?.path}

                        component={<Link to={el?.menu_name == "Appointments" && props?.user_data?.role == 4 ? "/" : el?.path} />}
                        onClick={() => {
                          props?.sidebar === "collapsed"
                            ?
                            handleOpenCollapse()
                            :
                            handleCloseCollapse()
                        }}
                      >
                        {icon === false ? (
                          <img src={el?.menu_icon} className="menu-icons-collapsed" alt="" />
                        ) : (
                          <span>
                            <img src={el?.menu_icon} className="menu-icons" alt="" />
                            <strong>{el?.menu_name}</strong>
                          </span>
                        )}
                      </MenuItem>
                    </>
                  );
                })}
                {/* <div style={icon === false ? { width: '75%' } : null}>
                  <MenuItem
                    style={{ marginRight: '5px' }}
                    active={location.pathname === "/services"}
                    component={<Link to={"/services"} />}
                  >
                    <AiOutlineScissor style={{ fontSize: '25px' }} /><strong>{props.sidebar === "collapsed" ? "" : "Services"}</strong>
                  </MenuItem>
                </div> */}
              </Menu>
              <div
                className={`sidebar_footer_layout ${props?.sidebar === "collapsed" ? "collapsed" : ""
                  }`}
              >
                {props?.sidebar === "collapsed" ? (
                  <div>
                    <img
                      className="harrisia_icon img-fluid col-12"
                      src={harrisia_icon}
                      alt="harrisia_logo"
                    />
                  </div>
                ) : (
                  <>
                    <div className="poweredBy">
                      <span className="poweredBy-text">

                        Powered by{" "}

                        <img
                          src={harrisia_logo}
                          alt="harrisia"
                          className="harrisia_logo"
                        ></img>

                      </span>
                    </div>
                  </>
                )}
              </div>
            </Sidebar>
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state.data.sidebar,
    user_data: state.data.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
    setSelectedLang: (data) => dispatch(setSelectedLang(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebari);

// export default Sidebari
