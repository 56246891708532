
import React, { useEffect, useState } from "react";
import Photo from "../../../assets/images/termini-beauty-logo.png";
import {
  LoginLayout,
  Heading,
  FormForRegister,
  InputEmail,
  InputPass,
  ButtonsReg,
  Paragraf,
  FormRegisterLayout,
  FormRegister,
  Image,
  Heading3,
  Text,
  Paragraph,
  Labels,
  ButtonRegister,
  ButtonReg,
  Register,
  Registerbutton,
  RadioButton,
  InputRadio,
  EyePass,
  NameSurnameDiv,
  NameFirst,
  NameFirstSurnamee,
  Loginbutton,
  ButtonR,
  Buttons,
  DivForLabelAndInputLang,
  LabelProfile,
  SelectOptionn,
} from "../../styles/MainLayout";
import axios from "../../../axios";
import { connect } from "react-redux";
// import { setUserToken } from "../../../redux/Functions/actions";
import Aos from "aos";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import LogoTermini from "../../../assets/images/termini-beauty-logo.png"
import PhotoSignUp from "../../../assets/images/landingPage-Mockups/fotoweb4-0(SIGNUP).png"
import { IoIosLock } from "react-icons/io";
import { toast } from "react-toastify";
// import bcrypt  from "bcryptjs";
import sign from "jwt-encode"
import "../../LandingPage/LandingPage.scss"
import jwtDecode from "jwt-decode";
import { setExpiredToken, setLoggedIn, setUserData, setUserToken } from "../../../redux/Functions/actions";
import Footer from "../../LandingPage/Footer";
import LoginForm from "../LoginForm/LoginForm";


const RegisterForm = (props) => {

  const [firstName, setfisrtName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState();
  const [allGenders, setAllGenders] = useState([]);
  const [selectedGender, setSelectedGender] = useState();




  const navigate = useNavigate();

  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate('/login');
  };

  Aos.init();
  //AXIOS

  //PASSWORD ENCRYPTION


  const secretKey = process.env.REACT_APP_SECRET_KEY
  const [salonName, setSalonName] = useState();
  const [phone_number, setPhone_number] = useState();
  const [salonDescription, setSalonDescription] = useState();
  const [salonDescriptionEn, setSalonDescriptionEn] = useState();

  // console.log('env secret key ', process.env.REACT_APP_SECRET_KEY)
  let passEnc = sign(password, secretKey)

  //PASSWORD ENCRYPTION
  const body = {
    role_id: 2,
    name: firstName,
    surname: lastName,
    username: username,
    email: email,
    phone_number: phone_number,
    password: passEnc,
    default_language: defaultLanguage

  }
  const handleRegister = async (e) => {
    e.preventDefault();
    console.log("body", body)
    axios
      .post("/api/auth/signup", body)
      .then(async (dataa) => {
        // console.log("shkoj", dataa)
        toast.success("Registered!")
        let bodyLogin = {
          email: email,
          password: passEnc
        }
        // debugger;
        await axios.post("/api/auth/login", bodyLogin)
          .then(async (response) => {
            console.log("response", response)
            if (response.status === 200) {
              const secretKey = process.env.REACT_APP_SECRET_KEY
              const decodeToken = jwtDecode(response?.data?.token, secretKey)
              if (decodeToken?.role === 2 || decodeToken?.role === 4) {
                // MERI QITO DYA NE POST TE SALON DATA
                toast.success("You are logged in");
                console.log('token', response?.data)
                window.localStorage.setItem('token', response?.data?.token)
                props?.setUserToken(response.data.token);
                props?.setExpiredToken(decodeToken?.logout_timer);

                props?.setLoggedIn(true)

                let salonBody = {
                  owner_id: decodeToken.user_id,
                  name_al: salonName,
                  name_en: salonName,
                  desc_al: salonDescription,
                  desc_en: salonDescriptionEn,
                  created_by: decodeToken?.user_id,
                  subscription_id: 1
                }
                console.log("salonBody", salonBody)
                await axios.post("/api/store/salons", salonBody)
                  .then(async (newSalon) => {
                    props?.setUserData(
                      {
                        role: decodeToken?.role,
                        user_id: decodeToken?.user_id,
                        salon_id: newSalon?.data?.salon_data?.id,
                        auth: decodeToken?.auth,
                        name: decodeToken?.name,
                        surname: decodeToken?.surname,
                        gender: decodeToken?.gender,
                        language: decodeToken.language,
                        phoneNumber: decodeToken.phoneNumber
                      }
                    );

                    console.log("newSalon", newSalon)
                    toast.success("Created Your Salon")
                    if (decodeToken.role == 2) {
                      navigate('/salon');
                    } else {
                      navigate('/');
                    }

                    // console.log('data o daj', data.data)
                    console.log("decodeTokendecodeToken", decodeToken)
                  }
                  )
                  .catch(err => console.log('error creating salon :', err))

              }
              else if (decodeToken.role !== 2 || decodeToken.role !== 4) {
                toast.warning("You do not have access to this site!")
              }

              console.log("decodeToken", decodeToken)

            }
          })
          .catch((err) => {
            toast.error(err?.response?.data);
            console.log('error login:', err.response.data.error);
          });
        // props?.setUserToken(data.data.token)
        // const decodeToken = jwtDecode(data.data.token, "K0$$0V@-€ur0PJAN€")

      }).catch(err => {
        console.log("Register Error :", err)
        toast.error(err.response?.data || "An error occurred during registration.")
      }
      )
  }

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const getAllGenders = () => {
    axios.get("/api/client/genders/all")
      .then((res) => {
        setAllGenders(res.data)
      })
      .catch((err) => { console.log("error fetching genders : ", err) })
  }
  useEffect(() => {
    getAllGenders()
  }, [])
  return (
    <>
      <header className='headerLogin' style={{ background: 'white', width: '100%', top: '0', height: '100px' }}>
      </header>
      <div className=" container loginLayout" >
        <div className="logoTermini mt-5">
          <img src={LogoTermini} alt="" />
        </div>
        <div className="backgroundImage ">
          <div className="mt-5 formRegisterLayout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 rightText">
                  <div className="rightHeadingText">
                    <div className="textParagraf">
                      <div>
                        <h1 className='headingText'>Regjistrohu si <br /> biznes.</h1>
                      </div>
                      <div>
                        <p className="paragrafi">Shpejtë dhe lehtë.</p>
                      </div>
                    </div>
                  </div>
                  <div className="divImageSignUp">
                    <img src={PhotoSignUp} alt="" className="signUpImage" />
                  </div>
                </div>
                <div className="col-md-6  leftText">
                  <Register onSubmit={(e) => handleRegister(e)}>
                    <InputEmail required onChange={(e) => {
                      setfisrtName(e.target.value);
                    }} type="text" placeholder="Emri"></InputEmail>
                    <NameFirstSurnamee>
                      <InputPass onChange={(e) => {
                        setLastName(e.target.value);
                      }} required id="last_name" name="last_name" type="text" placeholder="Mbiemri"></InputPass>
                      <InputPass onChange={(e) => {
                        setUsername(e.target.value);
                      }} required id="username" name="username" type="text" placeholder="Username"></InputPass>
                      <DivForLabelAndInputLang className="forDisable">
                        <LabelProfile> </LabelProfile>
                        {/* {console.log("users", users)} */}
                        <SelectOptionn
                          className="mt-0"
                          required
                          id="default_language"
                          name="default_language"
                          placeholder="Choose your gender"
                          value={selectedGender || null} // Set value to null initially
                          onChange={(event) => setSelectedGender(event.target.value)}
                        >
                          <option className="placeholder_gender" hidden key={null} value={null}>Choose your gender</option>
                          {selectedGender !== null && allGenders?.map(gender => (
                            <option key={gender.id} className="genders_options" value={gender.id}>{gender.name_al}</option>
                          ))}
                        </SelectOptionn>
                      </DivForLabelAndInputLang>
                    </NameFirstSurnamee>
                    <InputEmail onChange={(e) => { setPhone_number(e.target.value) }} required type="number" placeholder="Numri i telefonit"></InputEmail>
                    <InputEmail onChange={(e) => { setEmail(e.target.value) }} required placeholder="Email"></InputEmail>
                    <InputEmail
                      required
                      type={passwordType}
                      placeholder="Krijo fjalëkalim"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    >
                    </InputEmail>
                    {/* {passwordType === "password" ? <i class="fa fa-eye-slash passEye iconsForCursor" onClick={togglePassword}></i> : <i class="fa fa-eye passEye iconsForCursor" onClick={togglePassword}></i>} */}
                    <InputEmail onChange={(e) => setSalonName(e.target.value)} required id="salonName" placeholder="Emri i sallonit"></InputEmail>
                    <InputEmail onChange={(e) => { setSalonDescription(e.target.value) }} required placeholder="Përshkrim i shkurtë i sallonit"></InputEmail>
                    <InputEmail onChange={(e) => { setSalonDescriptionEn(e.target.value) }} required placeholder="Description of the salon"></InputEmail>

                    {/* <RadioButton>
  <p className="radioButton"><strong>Choose the language:</strong></p>
  <InputRadio onChange={(e) => {
    setDefaultLanguage(e.target.value)
  }}>
  <input type="radio" value={1} name="gender" /> Shqip
  <input type="radio" value={2} name="gender" /> English
  </InputRadio>
</RadioButton> */}
                    <Buttons>
                      <ButtonR type="submit">HAP LLOGARI</ButtonR>
                      <Loginbutton onClick={navigateToContacts}>KYÇU</Loginbutton>
                    </Buttons>
                    <div className="">
                      <Paragraf>Duke hapur llogari në termini.beauty ju pajtoheni me
                        <p onClick={() => navigate("/terms-and-conditions")}>termat dhe kushtet </p>
                      </Paragraf>
                    </div>
                  </Register>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // logged_in: state.data.logged_in,
    user_data: state.data.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setExpiredToken: (data) => dispatch(setExpiredToken(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
