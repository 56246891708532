import React, { useEffect } from 'react'
import AppointmentCalendar from "../../components/form/appointmentsCalendar"
import AppointmentTable from '../../components/form/AppointmentResponsiveTable'
import { useState } from 'react'
import { Switch } from 'antd'
import { SwitchStyle, SwitchStyle2, ApppointmentButton } from '../../components/styles/MainLayout'
import { useLocation } from 'react-router-dom'
import PendingAppointments from '../../components/form/PendingAppointments'
import axios from '../../axios'
const Appoinments = (props) => {
  console.log('ramadani', props)
  const location = useLocation()
  console.log('location', location.pathname)

  const [pending, setPending] = useState([])

  const pendingAppointment = () => {

    axios.get(`api/store/appointments/pendingAppointments/${props?.salonId}`)
      .then((response) => {
        setPending(response.data.pending_appointments)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log('pending', pending)
  useEffect(() => {
    pendingAppointment()
  }, [])
  return (
    <>
      {props?.view == 0 ?
        <>
          <SwitchStyle>
            <ApppointmentButton className='background_calendar_button' onClick={() => props?.setView(0)}>Kalendari</ApppointmentButton>
            <ApppointmentButton onClick={() => props?.setView(1)}>Lista</ApppointmentButton>
            <ApppointmentButton onClick={() => props?.setView(2)}>Në pritje <span className='text-danger'>{pending}</span></ApppointmentButton>
          </SwitchStyle>
          <AppointmentCalendar setView={() => props?.setView()} calendarView={props?.view} />
        </>
        :
        props?.view == 1 ?
          <>
            <SwitchStyle2>
              <ApppointmentButton onClick={() => props?.setView(0)}>Kalendari</ApppointmentButton>
              <ApppointmentButton className='background_calendar_button' onClick={() => props?.setView(1)}>Lista</ApppointmentButton>
              <ApppointmentButton onClick={() => props?.setView(2)}>Në pritje <span className='text-danger'>{pending}</span></ApppointmentButton>
            </SwitchStyle2>
            <AppointmentTable />
          </>
          : <>
            <SwitchStyle2>
              <ApppointmentButton onClick={() => props?.setView(0)}>Kalendari</ApppointmentButton>
              <ApppointmentButton onClick={() => props?.setView(1)}>Lista</ApppointmentButton>
              <ApppointmentButton className='background_calendar_button' onClick={() => props?.setView(2)}>Në pritje <span className='text-light'>{pending}</span></ApppointmentButton>
            </SwitchStyle2>
            <PendingAppointments pendingAppointment={pendingAppointment} />
          </>
      }
    </>
  )
}

export default Appoinments