import React, { useState, useEffect } from 'react';
import { UsersForm, Label, LabelStaff, LabelProfile, DivForLabelInput, FormTitle, FormControlApp, NameSurname, ModalAddButtonApp, NameFirst, Surname, DivSelect, DateTime, SelectOption, ButtonsModal, DivForLabelAndInput, InputProfileForm, FormControl, NameSurnameFirst, SelectOptionBox, ModalAddButtonUsers } from "../styles/MainLayout";
import axios from '../../axios';
import { setSideBar, setUserData } from '../../redux/Functions/actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ola from "../../assets/images/nails-design.jpg"
import "../../pages/salons/AddSalon/addSalons.scss"
import sign from 'jwt-encode';
const AppointmentForm = (props) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY
  const [users, setUsers] = useState([])
  const [addressId, setAddressId] = useState([])
  const [serviceId, setServiceId] = useState()
  const [services, setServices] = useState([])
  const [subServices, setSubservices] = useState([])
  const [clients, setClients] = useState([])
  const [image, setImage] = useState(null);
  const [salon, setSalon] = useState([])
  const [reserverEmail, setReserverEmail] = useState('')
  const [block, setBlock] = useState(false)

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const [addClient, setAddClient] = useState(false)
  const [payLoad, setPayLoad] = useState({
    date: "",
    client: "",
    service: "",
    staff: "",
    salon_address_id: "",
    duration: ""
  });

  const getServices = () => {
    axios.get(`/api/store/salon_services/all`).then(
      data => {
        console.log("serviset", data.data)
        setServices(data.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }
  console.log("services", services.filter(el => el.salon_address_id == payLoad.salon_address_id && el.salon_id == props?.user_data?.salon_id))
  const getSubService = () => {
    axios.get(`/api/store/sub_services/single/${serviceId}`).then(
      data => {
        console.log("serviset", data.data)
        setSubservices(data.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }
  const getSalon = () => {
    axios.get(`/api/store/salon_address/all_addresses/${props?.user_data?.salon_id}`).then(
      res => {
        console.log(res.data)
        setAddressId(res.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }
  const getUsers = () => {
    axios.get(`/api/store/users/salon_workers/${props?.user_data?.salon_id}`).then(data => {
      console.log('allusers get req', data.data);
      setUsers(data.data)
    }).catch(err => console.log(err));
  }
  const getClients = () => {
    axios.get(`/api/store/users/clients/${props?.user_data?.salon_id}/${props?.user_data?.user_id}`).then(data => {
      console.log('allusers get req', props?.user_data?.salon_id);
      setClients(data.data)
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    if (props.type === "add") {
      const defaultDate = new Date(props.data?.start);
      const adjustedDate = new Date(defaultDate.toISOString());
      console.log("adjustedDate", adjustedDate)
      const isoDateString = adjustedDate?.toISOString().substring(0, 16);
      setPayLoad({ ...payLoad, date: isoDateString });
    }
  }, [props.type, props.data?.start]);
  useEffect(() => {
    getUsers()
    getSalon()
    getSubService()
    getServices()
    getClients()
    getBusiness()
  }, [serviceId])


  const handleSelectChange = (e) => {
    const parameterName = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const durations = selectedOption.dataset.name;

    setPayLoad({ ...payLoad, service: parameterName, duration: durations });
  };


  const handleFormSubmit = (e) => {
    e.preventDefault();
    const time = payLoad.duration;
    const timeParts = time.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const totalMinutes = hours * 60 + minutes;

    const formData = new FormData();
    formData.append('salon_id', props?.user_data?.salon_id);
    formData.append('costumer_id', payLoad?.client);
    formData.append('appt_start', props.type == "add" ? new Date(currentTimeSlot.getTime() + 1 * 60 * 60 * 1000).toISOString().split("T")[1].slice(0, 8) : new Date(currentTimeSlot.getTime() + 0 * 60 * 60 * 1000).toISOString().split("T")[1].slice(0, 8));
    formData.append('appt_end', props.type == "add" ? new Date(currentTimeSlot.getTime() + 1 * 60 * 60 * 1000 + totalMinutes * 60 * 1000).toISOString().split("T")[1].slice(0, 8) : new Date(currentTimeSlot.getTime() + 0 * 60 * 60 * 1000 + totalMinutes * 60 * 1000).toISOString().split("T")[1].slice(0, 8));
    formData.append('salon_worker_id', payLoad?.staff);
    formData.append('service_id', payLoad?.service);
    formData.append('date_of_appointment', new Date(payLoad?.date)?.toISOString()?.split("T")[0]);
    formData.append('salon_address_id', payLoad?.salon_address_id)
    formData.append('reserver_name', e.target.reserver_name.value)
    formData.append('reserver_email', e.target.reserver_email.value)
    formData.append('reserver_note', e.target.reserver_note.value)
    formData.append('reserver_phone', e.target.reserver_phone.value)
    formData.append('goal_image_path', e.target.goal_image_path.files[0])


    axios.post("api/store/appointments/newAppt", formData)
      .then((response) => {
        toast.success("Appointment added successfully!")
        console.log("Appointment added successfully!", response);
        props?.handleModalClose()
        props?.getAppointments()

      })
      .catch((error) => {
        console.error("Error adding appointment:", error);
      });
  };
  const mySelectedSlot = new Date(payLoad?.date).getTime()
  const currentTimeSlot = new Date(mySelectedSlot + 1 * 60 * 60 * 1000)

  console.log("trigger", addClient)

  const getBusiness = () => {
    axios.get(`api/store/salons/single/${props?.user_data?.salon_id}`).then(
      data => {
        setSalon(data.data)
      }
    ).catch(err => {
      console.log(err)
    })
  }
  function getResEmail(id) {
    const resEmail = clients?.find(el => el?.id == id)?.email
    setReserverEmail(resEmail)
  }

// ADD CLIENT FROM
  function userForm() {

    const addUser = (e) => {
      e.preventDefault()
      let userData = {
        username: e.target?.username?.value,
        name: e?.target?.first_name?.value,
        surname: e?.target?.last_name?.value,
        email: e?.target?.email?.value,
        salon_id: props?.user_data?.salon_id,
        gender: e?.target?.gender?.value,
        phone_number: e?.target?.phone_number?.value,
        created_by: props?.user_data?.user_id,
        salon_name: salon?.name_al,
        default_language: 1
      }
      // alert(userData)
      axios.post(`/api/store/users/newClient`, userData).then(
        data => {
          console.log(data.data)
          setAddClient(false)
          toast.success('Client added successfully')
          getClients()
        }
      ).catch(
        err => {
          console.log(err)
          toast.error(err?.response?.data)
          // alert('sdi qa ka')
        }
      )
    }


    return (
      <>
        <UsersForm onSubmit={addUser}>
          <FormControl>
            <NameSurnameFirst className="row">
              <NameFirst className="col-md-6">
                <DivForLabelAndInput className="forDisable">
                  <LabelProfile >First Name</LabelProfile>
                  <InputProfileForm required id="first_name" name="first_name" placeholder="Clients first name" type="text" />
                </DivForLabelAndInput>
              </NameFirst>

              <Surname className="col-md-6">
                <DivForLabelAndInput className="forDisable">
                  <LabelProfile >Last Name</LabelProfile>
                  <InputProfileForm required id="last_name" name="last_name" placeholder="Clients last name" type="text" />
                </DivForLabelAndInput>
              </Surname>
            </NameSurnameFirst>
            <DivForLabelAndInput className="forDisable">
              <LabelProfile >Username</LabelProfile>
              <InputProfileForm required id="username" name="username" placeholder="Clients username" type="text" />
            </DivForLabelAndInput>
            <DivForLabelAndInput className="forDisable">
              <LabelProfile >Phone Number</LabelProfile>
              <InputProfileForm required id="phone_number" name="phone_number" placeholder="Clients phone number" type="number" />
            </DivForLabelAndInput>

            <DivForLabelAndInput className="forDisable">
              <LabelProfile> Email </LabelProfile>
              <InputProfileForm required id="email" name="email" placeholder="Clients email" type="email" />
            </DivForLabelAndInput>
            <SelectOptionBox className="row m-0">
              <DivForLabelAndInput className="col-md-6">
                <SelectOption required id="gender" name="gender">
                  <option>Select Gender</option>
                  <option value={1}>Male</option>
                  <option value={2}>Female</option>
                </SelectOption>
              </DivForLabelAndInput>
            </SelectOptionBox>
          </FormControl>
          <div className="d-flex justify-content-center gap-2">
            <ModalAddButtonUsers onClick={() => setAddClient(false)}>Cancel</ModalAddButtonUsers>
            <ModalAddButtonUsers type="submit">Add</ModalAddButtonUsers>
          </div>
        </UsersForm>
      </>
    )
  }


  //TIME BLOCK FORM
  function TimeBlock() {

    const AddBlock = (e) => {
      e.preventDefault();
    
      const apptStart = e?.target?.appt_start?.value;
      const apptEnd = e?.target?.appt_end?.value;
    
      if (apptStart < apptEnd) {
        const formData = new FormData();
    
        formData.append("appt_start", `${apptStart}:00`.slice(0,8));
        formData.append("appt_end", `${apptEnd}:00`.slice(0,8));
        formData.append("salon_id", props?.user_data?.salon_id);
        formData.append("salon_address_id", users?.find(el => el.id == e?.target?.salon_worker_id?.value)?.salon_address_id );
        formData.append("salon_worker_id", e?.target?.salon_worker_id?.value);
        formData.append("date_of_appointment", e?.target?.date_of_appointment?.value);
        formData.append("created_by", props?.user_data?.user_id);
    
        axios.post('api/store/appointments/newBlockTime', formData)
          .then(data => {
            console.log(data?.data);
            setBlock(false);
            toast.success("Block Time Successfully Added");
            props?.handleModalClose();
            props?.getAppointments();
          })
          .catch(err => {
            console.log(err);
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Appointment start time must be earlier than appointment end time");
      }
    };
    return (
      <>
        <div className="container">
          <form onSubmit={AddBlock}>
            <DivForLabelInput className="forDisable mx-auto mt-3 w-50">
              <DateTime
                type="date"
                required
                id='date_of_appointment'
                name='date_of_appointment'
                defaultValue={props?.type == "add+"? "" :new Date(payLoad?.date)?.toISOString()?.split("T")[0]}
              />
            </DivForLabelInput>
            <div className="d-flex gap-3 justify-content-center">
              <div className="col-5">
                <input defaultValue={props?.type == "add+"? "" :new Date(currentTimeSlot?.getTime() + 1 * 60 * 60 * 1000)?.toISOString()?.split("T")[1]?.slice(0, 8)} type="time" required className='w-100 inputWorkingHours' name="appt_start" id="appt_start" />
              </div>
              -
              <div className="col-5">
                <input required className='w-100 inputWorkingHours' type="time" name="appt_end" id="appt_end" />
              </div>
            </div>
            <p className='mt-3 ps-3'>
              Which Employee :
            </p>
            <DivForLabelInput className="forDisable">
              <SelectOption
               id='salon_worker_id'
               name='salon_worker_id'
                required
              >
                <option value="">Select Employee</option>
                {users?.filter(el => el?.role_id == 4).map(el => {
                  return <option value={el.id}>{el?.name + " " + el?.surname}</option>
                })}
              </SelectOption>
            </DivForLabelInput>
            {/* <p className='mt-3 ps-3'>
              Description :
            </p>
            <textarea name="" id="" className='w-100 inputWorkingHours' rows="4" /> */}
            <ButtonsModal className='mt-5'>
              <ModalAddButtonApp type="button" onClick={() => setBlock(false)}>Cancel</ModalAddButtonApp>
              <ModalAddButtonApp type="submit">Add</ModalAddButtonApp>
            </ButtonsModal>
          </form>
        </div>
      </>
    )
  }

  //MAIN VIEW
  return (
    <>
      <FormTitle className="d-flex justify-content-center mt-3">{addClient == true ? "Add Client" : addClient == false && block == false ? "Appointment": "Block Time"}</FormTitle>
      <hr />
      <div>
        <div className="d-flex justify-content-center">
          {addClient == false && block == false ?
            <button className='btn btn-md btn-danger' onClick={() => setBlock(!block)}>
              Block
            </button>
            :
            ""
          }
        </div>
        {/* <UsersForm> */}
        {addClient == false && block == false ?
          <FormControlApp onSubmit={handleFormSubmit}>
            <DivSelect>
              <NameFirst>
                <LabelProfile>Date</LabelProfile>
                <DivForLabelInput className="forDisable">
                  {props.type == "add" ?
                    <DateTime
                      type="datetime"
                      value={new Date(currentTimeSlot)?.toLocaleString()}
                      onChange={(e) => setPayLoad({ ...payLoad, date: e.target.value })}
                      required
                    />
                    :
                    <DateTime
                      type="datetime-local"
                      onChange={(e) => setPayLoad({ ...payLoad, date: e?.target?.value })}
                      required
                    />
                  }
                </DivForLabelInput>
              </NameFirst>

              <NameSurname>
                <Surname>
                  <LabelProfile>Client</LabelProfile>
                  <DivForLabelInput className="forDisable">
                    <SelectOption
                      value={payLoad.client}
                      onChange={(e) => e.target.value !== 'add new' ? (setPayLoad({ ...payLoad, client: e.target.value }), setAddClient(false), getResEmail(e.target.value)) : (setAddClient(true))}
                      required
                    >
                      <option hidden value="">Select Client</option>
                      <option className='bg-success text-light' value={'add new'}>Add new client</option>
                      {clients?.map(el => {
                        return <option value={el.id}>{el?.name + " " + el?.surname}</option>
                      })}
                    </SelectOption>
                  </DivForLabelInput>
                </Surname>
                <NameFirst>
                  <LabelProfile>Address</LabelProfile>
                  <DivForLabelInput className="forDisable">
                    <SelectOption
                      onChange={(e) => setPayLoad({ ...payLoad, salon_address_id: e.target.value })}
                      required
                    >
                      <option value="">Select Address</option>
                      {addressId?.map(el => {
                        return <option value={el.id}>{el.name_al}</option>
                      })}
                    </SelectOption>
                  </DivForLabelInput>
                </NameFirst>
              </NameSurname>

              <NameFirst>
                {payLoad.salon_address_id == "" ? ""
                  :
                  <>
                    <LabelProfile>Service</LabelProfile>
                    <DivForLabelInput className="forDisable">
                      <SelectOption
                        value={payLoad.service}
                        onChange={handleSelectChange}
                        required
                        id='param'
                      >
                        <option value="">Select Service</option>
                        {services.filter(el => el.salon_address_id == payLoad.salon_address_id && el.salon_id == props.user_data.salon_id).map(el => {
                          return <option data-name={el?.duration} value={el.id}>{el.name_al}</option>
                        })}
                      </SelectOption>
                    </DivForLabelInput>
                  </>
                }
              </NameFirst>
              <Surname>
                {payLoad.salon_address_id == "" ? ""
                  :
                  <>
                    <LabelProfile>Staff</LabelProfile>
                    <DivForLabelInput className="forDisable">
                      <SelectOption
                        value={payLoad.staff}
                        onChange={(e) => setPayLoad({ ...payLoad, staff: e.target.value })}
                        required
                      >
                        <option value="">Select Staff</option>
                        {users?.filter(el => el.role_id == 4 && payLoad.salon_address_id == el.salon_address_id).map(el => {
                          return <option value={el.id}>{el?.name + " " + el?.surname}</option>
                        })}
                      </SelectOption>
                    </DivForLabelInput>
                  </>
                }

                <NameFirst>
                  <DivForLabelAndInput className="forDisable">
                    <LabelProfile>reserver name</LabelProfile>
                    <InputProfileForm required id='reserver_name' name='reserver_name' type='text' />
                  </DivForLabelAndInput>
                </NameFirst>

                <NameFirst>
                  <DivForLabelAndInput className="forDisable">
                    <LabelProfile>reserver email</LabelProfile>
                    <InputProfileForm defaultValue={reserverEmail == undefined ? "" : reserverEmail} required id='reserver_email' name='reserver_email' type='email' />
                  </DivForLabelAndInput>
                </NameFirst>
                <NameFirst>
                  <DivForLabelAndInput className="forDisable">
                    <LabelProfile>reserver phone</LabelProfile>
                    <InputProfileForm pattern='[0-9]' required id='reserver_phone' name='reserver_phone' type='number' />
                  </DivForLabelAndInput>
                </NameFirst>

                <NameFirst>
                  <DivForLabelAndInput className="forDisable">
                    <LabelProfile>Notes</LabelProfile>
                    <textarea id='reserver_note' name='reserver_note' type='textarea' />
                  </DivForLabelAndInput>
                </NameFirst>
              </Surname>


            </DivSelect>
            <div className="border p-2 chooseFile">
              <img className='mb-2' src={image != null ? URL.createObjectURL(image) : ''} style={{ MaxWidth: "400px", maxHeight: '120px' }} alt="image example" />
              <input onChange={handleImageChange} type="file" name="goal_image_path" id="goal_image_path" />
              <label className="custom-file-upload"> Upload service example
              </label>
            </div>
            <ButtonsModal className='mt-5'>
              <ModalAddButtonApp type="button" onClick={() => props?.handleModalClose()}>Cancel</ModalAddButtonApp>
              <ModalAddButtonApp type="submit">Add</ModalAddButtonApp>
            </ButtonsModal>

          </FormControlApp>
          :
          block == true ?
            TimeBlock()
            :
            addClient == true && block == false ?
              userForm()
              :
              ""
        }

        {/* </UsersForm> */}
      </div>
    </>

  );
};

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    dataFilter: state?.data?.dataFilter,
    user_data: state?.data?.user_data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
    setUserData: (data) => dispatch(setUserData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentForm);
