import React, { useEffect, useState } from 'react'
import {
  ModalDarkBackGround, ModalStyleAppointment,
  ModalCloseButtonAppointment, NameFirstAddress,
  InputProfileForm, DivForLabelAndInput, DivForLabelAndInputEmail, CloseButton, ModalCloseButton, CloseButtonMobile
} from "../styles/MainLayout"
import { ModalStyleMobile, ModalCloseButtonMobile, } from "../styles/MainLayoutResponsive"
import {
  DivForLabelInputClient, DivForLabelInputAddress,
  LabelProfile, DivForLabelInput, FormTitle, FormControlApp,
  NameSurname, ModalAddButtonApp, NameFirst,
  DivSelect, DateTime, SelectOption, ButtonsModal
} from "../styles/MainLayout";
import axios from "../../axios"
import ModalPortal from '../../portal/ModalPortal';
import AppointmentForm from '../form/AppointmentForm';
import { toast } from "react-toastify"
import { setSideBar } from '../../redux/Functions/actions';
import { connect } from 'react-redux';

const AppointmentModal = (props) => {
  console.log("props224", props?.data)
  const [serviceName, setServiceName] = useState(props.data?.service_data?.id)
  const [serviceDuration, setServiceDuration] = useState(props?.data?.salon_sub_service?.duration)
  const [services, setServices] = useState([])
  const [subServices, setSubservices] = useState([])
  const [addressId, setAddressId] = useState([])
  const [users, setUsers] = useState([])
  const [clients, setClients] = useState([])
  const [block, setBlock] = useState(false)
  const getServices = () => {
    axios.get(`/api/store/salon_services/all`).then(
      data => {
        console.log("serviset", data.data)
        setServices(data.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }

  const getSalon = () => {
    axios.get(`/api/store/salon_address/all_addresses/${props?.user_data?.salon_id}`).then(
      res => {
        console.log(res.data)
        setAddressId(res.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }
  console.log("props?.status", props?.status)
  console.log("address IDIDIDID props", props)
  const getUsers = () => {
    axios.get(`/api/store/users/salon_workers/${props?.user_data?.salon_id}`).then(data => {
      console.log('allusers get req', data.data);
      setUsers(data.data)
    }).catch(err => console.log(err));
  }
  const getClients = () => {
    axios.get(`/api/store/users/clients/${props?.data?.salon_id}/${props?.user_data?.user_id}`).then(data => {
      console.log('allusers get req', data.data);
      setClients(data.data)
    }).catch(err => console.log(err));
  }
  console.log("props?.data?.user_data?.user_id", props?.user_data?.user_id)
  const handleSelectChange = (e) => {
    const parameterName = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const duration = selectedOption.dataset.name;
    setServiceDuration(duration)
    setServiceName(parameterName)
  };


  const EditAppointment = (e) => {
    e.preventDefault()
    const time = serviceDuration;
    const timeParts = time.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const totalMinutes = hours * 60 + minutes;

    const apptStartDateTime = new Date(e.target.date.value);
    const apptEndDateTime = new Date(apptStartDateTime.getTime() + totalMinutes * 60000);
    const apptEndHours = String(apptEndDateTime.getHours()).padStart(2, '0');
    const apptEndMinutes = String(apptEndDateTime.getMinutes()).padStart(2, '0');
    const apptEnd = `${apptEndHours}:${apptEndMinutes}:00`;

    let EditAppointment = {
      salon_id: props.user_data?.salon_id,
      costumer_id: e?.target?.client?.value,
      app_start: `${e.target.date.value.split("T")[1]}:00`,
      app_end: apptEnd,
      salon_worker_id: e?.target?.worker?.value,
      service_id: serviceName,
      date_of_appointment: e.target.date.value.split("T")[0],
      salon_address_id: e?.target?.salon_address_id?.value,
      reserver_name: e.target.reserver_name.value,
      reserver_email: e.target.reserver_email.value,
      reserver_note: e.target.reserver_note.value,
      reserver_phone: e.target.reserver_phone.value,
      updated_at: new Date(),
      status: Number(e?.target?.status?.value),
      created_by: props?.user_data?.user_id,
    };
    console.log("Edit Appointment", EditAppointment)
    axios.put(`api/store/appointments/${props?.data?.id}`, EditAppointment)
      .then((response) => {
        toast.success("Appointment added successfully!")
        console.log("Appointment added successfully!", response);
        props?.handleModalClose();
        props?.getAppointments();
      })
      .catch((error) => {
        console.error("Error adding appointment:", error);
      });

  }
  useEffect(() => {
    getUsers()
    getSalon()
    getServices()
    getClients()
  }, [])
  const [viewportSize, setViewportSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });
  console.log('`${process.env.REACT_APP_API}${props.data?.goal_image_path}`', `${process.env.REACT_APP_API}${props.data?.goal_image_path}`)

  // const EditStatus = () =>{
  //   axios.put(`/api/store/appointments/changeStatus/${id}`, { status_id: data, service_id: sid, appt_time: time, appt_date: date })
  //   .then((response) => {
  //       toast.success("Appointment updated sucessfully.")
  //       props?.handleModalClose();
  //       props?.getAppointments();
  //   }).catch(err => {
  //       toast.error("Something went wrong")
  //       console.log(err)
  //   })
  // }
  function TimeBlock() {

    const EditBlock = (e) => {
      e.preventDefault()
      const apptStart = e?.target?.appt_start?.value;
      const apptEnd = e?.target?.appt_end?.value;

      if (apptStart < apptEnd) {
        const formData = new FormData();
        formData.append("appt_start", props?.data?.app_start !== e?.target?.appt_start?.value ? `${e?.target?.appt_start?.value}:00`?.slice(0,8) : props?.data?.app_start.slice(0,8))
        formData.append("appt_end", props?.data?.appt_end !== e?.target?.appt_end?.value ? `${e?.target?.appt_end?.value}:00`?.slice(0,8) : props?.data?.app_end.slice(0,8))
        formData.append("salon_id", props?.user_data?.salon_id)
        formData.append("salon_address_id", users?.find(el => el.id == e?.target?.salon_worker_id?.value)?.salon_address_id)
        formData.append("salon_worker_id", e?.target?.salon_worker_id?.value)
        formData.append("date_of_appointment", e?.target?.date_of_appointment?.value)
        formData.append("created_by", props?.user_data?.user_id)

        axios.put(`api/store/appointments/editBlockedTimestamp/${props?.data?.id}`, formData).then(
          data => {
            console.log(data?.data)
            setBlock(false)
            toast.success("Block Time  Successfully Edited")
            props?.handleModalClose();
            props?.getAppointments();
          }
        ).catch(err => {
          console.log(err)
          toast.error("Something went wrong")
        })
        console.log("block data", formData);
      }
      else {
        toast.error("Appointment start cannot be later than appointment end")
      }

    }
    const DeleteBlock = (e) => {
      axios.delete(`api/store/appointments/deleteBlockedTimestamp/${props.data.id}`).then(
        data => {
          console.log(data.data)
          toast.success(`Delete Block Time Successfully`)
          props?.handleModalClose();
          props?.getAppointments();

        }
      ).catch(err => {
        console.log(err)
        toast.error('Error in deleting block time')
      })
    }
    return (
      <>
        <div className="container">
          <div className='d-flex justify-content-center'>
            <button type="button" onClick={(e) => DeleteBlock(e)} className='btn btn-md btn-danger'>Delete</button>
          </div>
          <form onSubmit={EditBlock}>
            <DivForLabelInput className="forDisable mx-auto mt-3 w-50">
              <DateTime
                type="date"
                required
                id='date_of_appointment'
                name='date_of_appointment'
                defaultValue={props?.data?.date_of_appointment}
              />
            </DivForLabelInput>
            <div className="d-flex gap-3 justify-content-center">
              <div className="col-5">
                <input type="time" required defaultValue={props?.data?.app_start} className='w-100 inputWorkingHours' name="appt_start" id="appt_start" />
              </div>
              -
              <div className="col-5">
                <input required defaultValue={props?.data?.app_end} className='w-100 inputWorkingHours' type="time" name="appt_end" id="appt_end" />
              </div>
            </div>
            <p className='mt-3 ps-3'>
              Wich Employee :
            </p>
            <DivForLabelInput className="forDisable">
              <SelectOption
                id='salon_worker_id'
                name='salon_worker_id'
                required
              >
                <option hidden value={props?.data?.salon_worker_id}>{users.filter(el => el?.id == props?.data?.salon_worker_id)?.map(el => { return el?.name + " " + el?.surname })}</option>
                {users?.filter(el => el.role_id == 4).map(el => {
                  return <option value={el.id}>{el?.name + " " + el?.surname}</option>
                })}
              </SelectOption>
            </DivForLabelInput>
            {/* <p className='mt-3 ps-3'>
            Description :
          </p>
          <textarea name="" id="" className='w-100 inputWorkingHours' rows="4" /> */}
            <ButtonsModal className='mt-5'>
              <ModalAddButtonApp type="button" onClick={() => props?.handleModalClose()}>Cancel</ModalAddButtonApp>
              <ModalAddButtonApp type="submit">Edit</ModalAddButtonApp>
            </ButtonsModal>

          </form>
        </div>
      </>
    )
  }



  if (props?.isOpen) {
    if (viewportSize > 800) {
      // ----DESKTOP VIEW---- //
      return (
        <>
          <ModalPortal>
            <ModalDarkBackGround onClick={() => props.handleModalClose()} />
            <ModalStyleAppointment>

              <CloseButton>
                <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
              </CloseButton>
              {props?.type == "add" || props?.type == "add+" ?
                <>
                  <AppointmentForm data={props?.appointment} type={props?.type} handleModalClose={props.handleModalClose} getAppointments={props.getAppointments} />
                </>
                :
                props?.type == "edit" ?

                  <>
                    <FormTitle className="d-flex justify-content-center mt-3">Edit Appointment</FormTitle>
                    <hr />
                    {block == false && props?.data?.status == 7 ?
                      TimeBlock()
                      :
                      <FormControlApp onSubmit={EditAppointment}>
                        <DivSelect>
                          <LabelProfile>Date</LabelProfile>
                          <DivForLabelInput className="forDisable" >
                            <DateTime
                              min={new Date().toISOString().slice(0, 16)}
                              id='date'
                              name='date'
                              type='datetime-local'
                              defaultValue={props?.data.date_of_appointment + "T" + props.data.app_start}
                              required
                            />
                          </DivForLabelInput>


                          <LabelProfile>Client</LabelProfile>
                          <DivForLabelInput className="forDisable">
                            <SelectOption
                              name='client'
                              id='client'
                            >
                              <option hidden value={props?.data?.costumer_id}>{clients?.filter(el => el?.id == props?.data?.costumer_id)?.map(el => { return el?.name + " " + el?.surname })}</option>
                              {clients?.map(el => {
                                return <option value={el?.id}>{el?.name + " " + el?.surname}</option>
                              })}
                            </SelectOption>
                          </DivForLabelInput>

                          <LabelProfile>Service</LabelProfile>
                          <DivForLabelInput className="forDisable">
                            <SelectOption
                              disabled
                              onChange={handleSelectChange}
                              required
                            >
                              <option hidden data-name={props?.data?.salon_sub_service?.duration} value={props?.data?.service_id}>{services?.filter(el => el?.id == props?.data?.service_id).map(el => { return el?.name_al })}</option>
                              {services?.map(el => {
                                return <option data-name={el?.duration} key={el?.id} value={el?.id}>{el?.name_al}</option>
                              })}
                            </SelectOption>
                          </DivForLabelInput>

                          <NameSurname className='row'>
                            <NameFirst className='col-md-6'>
                              <LabelProfile>Staff</LabelProfile>
                              <DivForLabelInputClient className="forDisable">
                                <SelectOption
                                  id='worker'
                                  name='worker'
                                  required
                                >
                                  <option hidden value={props?.data?.salon_worker_id}>{users.filter(el => el?.id == props?.data?.salon_worker_id)?.map(el => { return el?.name + " " + el?.surname })}</option>
                                  {users?.filter(el => el?.role_id === 4 && props?.user_data?.salon_id === el?.salon_id)?.map(el => (
                                    <option key={el?.id} value={el?.id}>{el?.name + " " + el?.surname}</option>
                                  ))}
                                </SelectOption>
                              </DivForLabelInputClient>
                            </NameFirst>

                            <NameFirstAddress className='col-md-6'>

                              <LabelProfile>Address</LabelProfile>
                              <DivForLabelInputAddress className="forDisable">
                                <SelectOption
                                  id='salon_address_id'
                                  name='salon_address_id'
                                  required
                                >
                                  <option hidden value={props?.data?.salon_address_id}>{addressId?.filter(el => el?.id == props?.data?.salon_address_id)?.map(el => { return el.name_al })}</option>
                                  {addressId?.map(el => {
                                    return <option value={el?.id}>{el?.name_al}</option>
                                  })}
                                </SelectOption>
                              </DivForLabelInputAddress>
                            </NameFirstAddress>
                          </NameSurname>

                          <NameSurname className='row'>
                            <NameFirst className='col-md-6'>
                              <DivForLabelAndInput className="forDisable">
                                <LabelProfile>reserver name</LabelProfile>
                                <InputProfileForm required defaultValue={props?.data?.reserver_name} placeholder='Reserver Name' id='reserver_name' name='reserver_name' type='text' />
                              </DivForLabelAndInput>
                            </NameFirst>

                            <NameFirst className='col-md-6'>
                              <DivForLabelAndInput className="forDisable">
                                <LabelProfile>reserver email</LabelProfile>
                                <InputProfileForm required defaultValue={props?.data?.reserver_email} placeholder='Reserver Email' id='reserver_email' name='reserver_email' type='email' />
                              </DivForLabelAndInput>
                            </NameFirst>
                          </NameSurname>

                          <NameFirst>
                            <DivForLabelAndInput className="forDisable">
                              <LabelProfile>reserver phone</LabelProfile>
                              <InputProfileForm required defaultValue={props?.data?.reserver_phone} placeholder='Reserver Phone' id='reserver_phone' name='reserver_phone' type='number' />
                            </DivForLabelAndInput>
                          </NameFirst>

                          <NameFirst>
                            <DivForLabelAndInput className="forDisable">
                              <LabelProfile>Notes</LabelProfile>
                              <textarea defaultValue={props?.data?.reserver_note} className='textAreaClass' id='reserver_note' name='reserver_note' type='textarea' />
                            </DivForLabelAndInput>
                          </NameFirst>
                          <NameFirst>
                            <NameFirstAddress>

                              <LabelProfile>Status</LabelProfile>
                              <DivForLabelInputAddress className="forDisable">
                                <SelectOption
                                  id='status'
                                  name='status'
                                  required
                                >
                                  <option hidden value={props?.data?.status}>
                                    {
                                      props.data?.status == 1 ? "Active" :
                                        props.data?.status == 2 ? "Canceled" :
                                          props.data?.status == 3 ? "Finished" :
                                            props.data?.status == 4 ? "Scheduled" :
                                              props.data?.status == 5 ? "Pending" :
                                                props.data?.status == 6 ? "Missed" :
                                                  ""
                                    }
                                  </option>
                                  <option value={1}>Active</option>
                                  <option value={2}>Canceled</option>
                                  <option value={3}>Finished</option>
                                  <option value={4}>Scheduled</option>
                                  <option value={5}>Pending</option>
                                  <option value={6}>Missed</option>
                                </SelectOption>
                              </DivForLabelInputAddress>
                            </NameFirstAddress>
                            <DivForLabelAndInput className="forDisable">
                              <LabelProfile>Service Example</LabelProfile>
                              <img src={`${process.env.REACT_APP_API}${props.data?.goal_image_path}`} className='w-50' />
                            </DivForLabelAndInput>
                          </NameFirst>
                          <br />
                          {/* <NameSurname> */}
                          <>
                          </>
                          <br />
                        </DivSelect>
                        <ButtonsModal>
                          <ModalAddButtonApp type="button" onClick={() => props.handleModalClose()}>Cancel</ModalAddButtonApp>
                          <ModalAddButtonApp type="submit">Edit</ModalAddButtonApp>
                        </ButtonsModal>
                      </FormControlApp>
                    }
                    <div>

                    </div>
                  </>
                  : ""
              }
            </ModalStyleAppointment>
          </ModalPortal>
        </>
      )
    }
    //   ----MOBILE VIEW----- //
    else {
      return (
        <>
          <ModalDarkBackGround onClick={() => props.handleModalClose()} />
          <ModalStyleMobile>
            <CloseButtonMobile>

              <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
            </CloseButtonMobile>
            {props?.type == "add" || props?.type == "add+" ?
              <>
                <AppointmentForm data={props?.appointment} type={props?.type} handleModalClose={props?.handleModalClose} getAppointments={props?.getAppointments} />
              </>
              :
              props?.type === "edit" ?
                <>
                  <div>
                    <FormTitle className="d-flex justify-content-center mt-3">Edit Appointment</FormTitle>
                    <hr />
                    {block == false && props?.data?.status == 7 ?
                      TimeBlock()
                      :
                      <FormControlApp onSubmit={EditAppointment}>
                        <DivSelect>
                          <LabelProfile>Date</LabelProfile>
                          <DivForLabelInput className="forDisable" >
                            <DateTime
                              id='date'
                              name='date'
                              type='datetime-local'
                              defaultValue={props?.data?.date_of_appointment + "T" + props?.data?.app_start}
                            />
                          </DivForLabelInput>


                          <LabelProfile>Client</LabelProfile>
                          <DivForLabelInput className="forDisable">
                            <SelectOption
                              name='client'
                              id='client'
                              required
                            >
                              <option hidden value={props?.data?.costumer_id}>{clients?.filter(el => el?.id == props?.data?.costumer_id)?.map(el => { return el?.name })}</option>
                              {clients?.map(el => {
                                return <option value={el?.id}>{el?.name}</option>
                              })}
                            </SelectOption>
                          </DivForLabelInput>

                          <LabelProfile>Service</LabelProfile>
                          <DivForLabelInput className="forDisable">
                            <SelectOption
                              onChange={handleSelectChange}
                              required
                              disabled
                            >
                              <option hidden value={props?.data?.service_id}>{services?.filter(el => el?.id == props?.data?.service_id)?.map(el => { return el?.name_al })}</option>
                              {services?.map(el => {
                                return <option data-name={el?.duration} key={el?.id} value={el?.id}>{el?.name_al}</option>
                              })}
                            </SelectOption>
                          </DivForLabelInput>

                          <NameSurname>
                            <NameFirst>

                              <LabelProfile>Staff</LabelProfile>
                              <DivForLabelInputClient className="forDisable">
                                <SelectOption
                                  id='worker'
                                  name='worker'
                                  required
                                >
                                  <option hidden value={props?.data?.salon_worker_id}>{users.filter(el => el?.id == props?.data?.salon_worker_id)?.map(el => { return el?.name + " " + el?.surname })}</option>
                                  {users.filter(el => el?.role_id === 4 && props?.user_data?.salon_id === el?.salon_id)?.map(el => (
                                    <option key={el?.id} value={el?.id}>{el?.name + " " + el?.surname}</option>
                                  ))}
                                </SelectOption>
                              </DivForLabelInputClient>
                            </NameFirst>

                            <NameFirstAddress>

                              <LabelProfile>Address</LabelProfile>
                              <DivForLabelInputAddress className="forDisable">
                                <SelectOption
                                  id='salon_address_id'
                                  required
                                  name='salon_address_id'
                                >
                                  <option hidden value={props?.data?.salon_address}>{addressId.filter(el => el?.id == props?.data?.salon_address_id)?.map(el => { return el?.name_al })}</option>
                                  {addressId?.map(el => {
                                    return <option value={el?.id}>{el?.name_al}</option>
                                  })}
                                </SelectOption>
                              </DivForLabelInputAddress>
                            </NameFirstAddress>
                          </NameSurname>

                          <NameSurname>
                            <NameFirst>
                              <DivForLabelAndInput className="forDisable">
                                <LabelProfile>reserver name</LabelProfile>
                                <InputProfileForm defaultValue={props?.data?.reserver_name} required type='text' id='reserver_name' name='reserver_name' />
                              </DivForLabelAndInput>
                            </NameFirst>

                            <NameFirst>
                              <DivForLabelAndInputEmail className="forDisable">
                                <LabelProfile>reserver email</LabelProfile>
                                <InputProfileForm defaultValue={props?.data?.reserver_email} required type='email' id='reserver_email' name='reserver_email' />
                              </DivForLabelAndInputEmail>
                            </NameFirst>
                          </NameSurname>

                          <NameFirst>
                            <DivForLabelAndInput className="forDisable">
                              <LabelProfile>reserver phone</LabelProfile>
                              <InputProfileForm defaultValue={props?.data?.reserver_phone} type='number' id='reserver_phone' name='reserver_phone' />
                            </DivForLabelAndInput>
                          </NameFirst>

                          <NameFirst>
                            <DivForLabelAndInput className="forDisable">
                              <LabelProfile>Notes</LabelProfile>
                              <textarea defaultValue={props?.data?.reserver_note} className='textAreaClass' type='textarea' id='reserver_note' name='reserver_note'/>
                            </DivForLabelAndInput>
                          </NameFirst>
                          <NameFirstAddress>
                          <LabelProfile>Status</LabelProfile>
                              <DivForLabelInputAddress className="forDisable">
                                <SelectOption
                                  id='status'
                                  required
                                  name='status'
                                >
                                  <option hidden value={props?.data?.status}>
                                    {
                                      props.data?.status == 1 ? "Active" :
                                        props.data?.status == 2 ? "Canceled" :
                                          props.data?.status == 3 ? "Finished" :
                                            props.data?.status == 4 ? "Scheduled" :
                                              props.data?.status == 5 ? "Pending" :
                                                props.data?.status == 6 ? "Missed" :
                                                  ""
                                    }
                                  </option>
                                  <option value={1}>Active</option>
                                  <option value={2}>Canceled</option>
                                  <option value={3}>Finished</option>
                                  <option value={4}>Scheduled</option>
                                  <option value={5}>Pending</option>
                                  <option value={6}>Missed</option>
                                </SelectOption>
                              </DivForLabelInputAddress>
                          </NameFirstAddress>
                          <NameFirst>
                            <LabelProfile>Service Example</LabelProfile>
                            <img src={`${process.env.REACT_APP_API}${props.data?.goal_image_path}`} style={{ MaxWidth: "400px", maxHeight: '120px' }} />
                          </NameFirst>

                          <br />
                          <br />
                        </DivSelect>
                        <ButtonsModal>
                          <ModalAddButtonApp onClick={() => props?.handleModalClose()}>Cancel</ModalAddButtonApp>
                          <ModalAddButtonApp type="submit">Edit</ModalAddButtonApp>
                        </ButtonsModal>
                      </FormControlApp>}
                  </div>
                </>

                :
                ""
            }
          </ModalStyleMobile>
        </>

      )
    }
  }
  else
    return null
}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    user_data: state?.data?.user_data,
    dataFilter: state?.data?.dataFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentModal);
