import React from "react";
import { AddButton, DeleteModal, PaginationStyle, TableStyle, SalonDetailsModal, SalonDetailsModalMobile, TextForNothing } from "../../components/styles/MainLayout";
import axios from "../../axios";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setSideBar, setUserData } from "../../redux/Functions/actions";
import { Link, useParams } from "react-router-dom";
import { AddButtonMobile, CardStyle } from "../../components/styles/MainLayoutResponsive";
import SearchInputHeader from "../../components/form/SearchInputHeader";

const Clients = (props) => {
    const { id } = useParams();
    console.log("reduks", props?.user_data)
    const [dataToModal, setDataToModal] = useState(null);


    //MODAL STATES
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setEditIsOpen] = useState(false);
    const [singleUserData, setSingleUserData] = useState({})
    const [modalType, setModalType] = useState(null);
    //AXIOS API's
    const [allSalons, setAllSalons] = useState([])

    const getData = () => {
        axios.get(`/api/store/salon_address/all_addresses/${props?.user_data?.salon_id}`).then(data => {
            console.log('allusers get req', data.data);
            setAllSalons(data.data)
            setFilteredData(data.data.filter((el) => el.name_al))

        }).catch(err => console.log(err));
    }
    useEffect(() => {
        getData()
    }, [])
    // console.log("filteredList", filteredList);




    //MODAL STATES
    // const filterByContent = (filterValue) => {
    //     console.log("filterValue", filterValue);

    //     var updatedList = [...allSalons];

    //     updatedList = updatedList?.filter((el) =>
    //       el?.name_al?.toLowerCase().includes(filterValue?.toLowerCase())
    //     );

    //     setFilteredList(updatedList);
    //   };

    // Render the list
    // const salonList = filteredList.length > 0 ? filteredList : allSalons;
    //MODAL HANDLERS
    const handleDeleteModal = (data) => {
        setDataToModal(data)
        setIsOpen(true)

    }
    const handleEditSalon = () => {
        setEditIsOpen(true)
    }

    const handleModalClose = () => {
        setIsOpen(false);
        setEditIsOpen(false)
    }
    //MODAL HANDLERS

    const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW
    //FILTER
    const [filteredData, setFilteredData] = useState();
    const filterByContent = async (filterValue) => {
        console.log("filteri", filterValue);
        if (filterValue) {
            const filteredArray = allSalons?.filter((el) =>
                el?.name_al?.toLowerCase().includes(filterValue?.toLowerCase())
            );
            setFilteredData(filteredArray);
        } else if (filterValue === "") {
            setFilteredData(allSalons);
        }
    };

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(false);
    const startIndex = currentPage * 4;
    const endIndex = startIndex + 4;
    const numPages = Math.ceil(filteredData?.length / 4)

    const data = filteredData?.slice(startIndex, endIndex);
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    return <h1 className="text-center">Comming Soon!</h1>
    // if (viewportSize > 800)
    //     return (
    //         <>
    //             <SearchInputHeader filterByContent={filterByContent} />

    //             <TableStyle collapse={props?.sidebar}>
    //                 <table class="table table-hover">
    //                     <thead>
    //                         <tr>
    //                             {/* <th scope="col-1">ID</th> */}
    //                             <th scope="col">Reserver </th>
    //                             <th scope="col">Service</th>
    //                             <th scope="col">Worker</th>
    //                             <th scope="col">Address</th>
    //                             <th scope="col">Date and Time</th>
    //                             <th scope="col">Actions</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {data?.map(el => {
    //                             console.log("ola", allSalons)
    //                             const date = new Date(el?.created_at)
    //                             const formatDate = date.toLocaleDateString()
    //                             return (

    //                                 <tr>
    //                                     {/* <th scope="row" className="col-1">
    //                                         {el?.id}
    //                                     </th> */}
    //                                     <td className="col">{el?.name_al}</td>
    //                                     <td className="col">{el?.street_address_al}</td>
    //                                     <td className="col">{formatDate}</td>


    //                                     <td className="col">
    //                                         <div className="d-flex gap-3">
    //                                             <Link className="text-dark" to={`/editsalon/${el?.id}`}>
    //                                                 <i class="fa-regular fa-pen-to-square iconsForCursor" />
    //                                             </Link>
    //                                             <i onClick={() => handleDeleteModal(el)} class="fa-regular fa-trash-can iconsForCursor mt-1" />
    //                                         </div>
    //                                     </td>
    //                                 </tr>
    //                             )
    //                         })}
    //                     </tbody>
    //                 </table>
    //             </TableStyle>
    //             <PaginationStyle collapse={props?.sidebar}>
    //                 <ReactPaginate
    //                     previousLabel={"previous"}
    //                     nextLabel={"next"}
    //                     breakLabel={"..."}
    //                     breakClassName={"break-me"}
    //                     pageCount={numPages}
    //                     onPageChange={handlePageChange}
    //                     containerClassName={"pagination justify-content-center"}
    //                     activeClassName={"active"}
    //                     currentPage={allSalons?.length > 7 ? true : false}
    //                 />
    //             </PaginationStyle>
    //             <Link to={'/addsalon'}>
    //                 <AddButton>
    //                     <i type={"button"} className="fa-solid fa-circle-plus fa-xl iconsForCursor" style={{ color: "#FF5758" }}></i>
    //                 </AddButton>
    //             </Link>
    //         </>
    //     )
    // else {
    //     return (
    //         <>
    //             <SearchInputHeader filterByContent={filterByContent} />
    //             <SalonDetailsModalMobile collapse={props?.sidebar}>
    //                 <button className="btn btn-sm" style={{ backgroundColor: 'rgb(255, 87, 88)', color: 'white' }} onClick={() => setEditIsOpen(true)}>
    //                     Business Details
    //                 </button>
    //             </SalonDetailsModalMobile>
    //             <div className="container">
    //                 {data?.map((el) => {
    //                     //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
    //                     const date = new Date(el?.created_at)
    //                     const formatDate = date?.toLocaleDateString()
    //                     return (
    //                         <>
    //                             <CardStyle>
    //                                 <div className="mb-3">
    //                                     <div className="row">
    //                                         <div className="col-8">
    //                                             <ul className="list-group gap-3 pt-2 pb-2 ps-3">
    //                                                 {/* <li class="list-group"><span><strong>ID</strong>: {el?.id}</span></li> */}
    //                                                 <li class="list-group"><span><strong>Salon AL</strong>: {el.name_al}</span></li>
    //                                                 <li class="list-group"><span><strong>Salon EN</strong> : {el.name_en}</span></li>
    //                                                 <li class="list-group"><span><strong>Created_at</strong> : {formatDate}</span></li>

    //                                             </ul>
    //                                         </div>
    //                                         <div className="col-3 pt-2">
    //                                             <div className="d-flex gap-2">
    //                                                 <i class="" />
    //                                                 <i class="" />

    //                                                 <Link className="text-dark" to={`/editsalon/${el?.id}`}>
    //                                                     <i class="fa-regular fa-pen-to-square iconsForCursor" />
    //                                                 </Link>
    //                                                 <i onClick={() => handleDeleteModal(el)} class="fa-regular fa-trash-can iconsForCursor mt-1" />

    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </CardStyle>
    //                         </>
    //                     )
    //                 })
    //                 }
    //                 {data?.length == 0 ? (
    //                     <TextForNothing>
    //                         <h6 className="mt-5">
    //                             "There is no data available"
    //                         </h6>
    //                     </TextForNothing>
    //                 ) : (
    //                     <PaginationStyle collapse={props?.sidebar}>
    //                         <ReactPaginate
    //                             previousLabel={"previous"}
    //                             nextLabel={"next"}
    //                             breakLabel={"..."}
    //                             breakClassName={"break-me"}
    //                             pageCount={numPages}
    //                             onPageChange={handlePageChange}
    //                             containerClassName={"pagination justify-content-center"}
    //                             activeClassName={"active"}
    //                             currentPage={allSalons?.length > 7 ? true : false}
    //                         />
    //                     </PaginationStyle>
    //                 )}
    //             </div>
    //             <Link to={'/addsalon'}>
    //                 <AddButtonMobile>
    //                     <i type={"button"} className="fa-solid fa-circle-plus fa-xl iconsForCursor" style={{ color: "#FF5758" }}></i>
    //                 </AddButtonMobile>
    //             </Link>
    //         </>
    //     )
    // }
}
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar,
        dataFilter: state?.data?.dataFilter,
        user_data: state?.data?.user_data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data)),
        setUserData: (data) => dispatch(setUserData(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);