import styled, { css } from "styled-components";


//APPOINTMENTS CSS
export const DivForLabelAndInputUser = styled.div`
display: flex;
flex-direction: column;
border: 3px solid rgb(235 227 227);
border-radius: 5px;
width: 100%;
padding: 0px 4px;
margin-bottom: 4%;
background-color: transparent;
@media only screen and (max-width: 800px) {
width: 100%;
}
`
export const ModalAddButtonService = styled.button`

    border-radius: 12px;
    background-color: rgb(255, 87, 88);
    border: none;
    padding: 5px 20px;
    color: white;
`

export const ModalCloseButtonMobile = styled.div`
border-bottom-left-radius: 12px;
    background-color: rgb(255, 87, 88);
    border: 0px;
    width: 10%;
    padding: 11px;
    border-top-right-radius: 23px;
    text-align: center;
`

export const CloseButtonMobile = styled.div`
display: flex;
justify-content: end;
`

export const ModalStyleMobile = styled.div`
    position: fixed;
    z-index: 99;
    background-color: white;
    border-radius: 22px;
    width: 100%;
    height: 84.4%;
    bottom: 50px;
      overflow-y: scroll;

`
export const ModalAddButtonSalon = styled.button`
border-radius: 7px;
    background-color: white;
    border: 2px solid rgb(255, 87, 88);
    padding: 5px 20px;
    color: rgb(255, 87, 88);
    @media only screen and (min-width:1600px)  {
    }
`
export const NameSurnameDiv = styled.div`
display: flex;
justify-content: space-between;
/* gap: 40px; */
`
export const NameFirstSurnamee = styled.div`
display: flex;
flex-direction: column;
`


export const SalonDetailsModal = styled.div`
position: absolute;
  left: 300px;
  right: 30px;
  top: 81px;
transition: all 300ms ease-in-out;
  z-index: 9;
${(props) =>
    props?.collapse
      ? css`
position: absolute;
  left: 150px;
  right: 30px;
  top: 81px;
  z-index: 9;



  `
      :
      ``}
`
export const SalonDetailsModalMobile = styled.div`
position: absolute;
  left: 250px;
  right: 30px;
  /* top: 60px; */
transition: all 300ms ease-in-out;
  z-index: 9;
${(props) =>
    props?.collapse
      ? css`
position: absolute;
  left: 100px;
  right: 30px;
  top: 80px;
  z-index: 9;

  `
      :
      ``}
`
export const CalendarStyle = styled.div`
border-top: 5px solid #FF5758;
box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
border-radius: 10px;
background-color: white;
position: fixed;
left: 285px;
right: 30px;
top: 100px;
bottom: 10px;
transition: all 300ms ease-in-out;
z-index: 9;

${(props) =>
    props?.collapse
      ? css`
transition: left 300ms ease-in-out;
  border-top: 5px solid #FF5758;
box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
border-radius: 10px;
background-color: white;
position: fixed;
left: 120px;
right: 30px;
top: 100px;
z-index: 9;
bottom: 10px;
  `
      :
      ``}
`

//MODAL CSS

export const ModalStyle = styled.div`
position: fixed;
    border-top: 6px solid rgb(255, 87, 88);
    border-radius: 12px;
    background-color: white;
    z-index: 99999;
    inset: 36px 20% 20% 26%;
    width: 40%;
    height: 609px;
    margin-left: 6%;
    /* padding: 2%; */
    overflow: scroll;




    /* overflow-y: scroll; */
      @media only screen and (max-width: 650px) {
      position: fixed;
    border-top: 6px solid rgb(255, 87, 88);
    border-radius: 12px;
    /* background-color: red; */
    z-index: 99999;
    left: 20px;
    bottom: 20%;
    width: 80%;
    height: 500px;
    padding: 2%;
    top: 36px;
    right: 20%;
    
    }
      @media only screen and (min-width: 1600px) {
    top: 13%;
        width: 33%;
    height: 609px;
    margin-left: 9%
      }
`;

//TABLES
export const TableStyle = styled.div`
  border-top: 5px solid #FF5758;
  box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  border-radius: 10px;
  background-color: white;
  position: fixed;
  left: 20%;
  right: 10%;
  top: 15%;
  left: 300px;
  right: 45px;
  top: 115px;
  overflow-y: scroll;
  transition: all 300ms ease-in-out;
  ${(props) =>
    props?.collapse
      ? css`
  border-top: 5px solid #FF5758;
 box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  border-radius: 10px;
  background-color: white;
  position: fixed;
  left: 140px;
  right: 55px;
  top: 115px;



  
  `
      :
      ``
  }
  table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 10px 15px !important;
  }
  tr:nth-child(even) {
    background-color:#e1e5ee;
  }
  th,
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 40px;
  }
`;

export const ModalCloseButton = styled.div`
   border-bottom-left-radius: 12px;
    border-top-right-radius: 3px;
    background-color: rgb(255, 87, 88);
    border: 0px;
    display: flex;
    padding: 2px;
    color: white;
    flex-direction: row;
    justify-content: center;
    width: 6%;
 
`;
export const CloseButton = styled.div`
display: flex;
    justify-content: end;
`
export const ModalDarkBackGround = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  z-index: 99;
`;
export const AddButton = styled.div`
  /* top: unset; */
  bottom: 2%;
  right: 1%;
  position: fixed;
  font-size: 30px;
`;

export const UsersForm = styled.form`

  @media only screen and (max-width: 800px) {
    background-color: white;
    border-radius: 20px;
        /* height: 20px; */
    }
 
`;

export const FormControl = styled.div`
    width: 100%;
    padding: 4%;
    border-radius: 10px;
  

`;
export const Label = styled.label`
width: 60%;
    font-size: 15px;
    background: rgb(239 242 245);
    font-weight: 500;
    border-radius: 14px 2px;
    border-top: 3px solid rgb(255, 87, 88);
    border-right: 3px solid rgb(255, 87, 88);
    border-left: 3px solid rgb(255, 87, 88);
    padding: 4px;
    background: rgb(239 242 245);

`;
export const MapStyle = styled.div`
width: 100%;
display: flex;
`
export const Input = styled.input`
width: 100%;
    padding: 1%;
    border-radius: 1px 15px;
    margin-bottom: 1%;
    background-color: white;
    border: 2px solid #e9e2e2;

    @media only screen and (max-width: 800px) {
width: 83%;
    }
`
export const DivForLabelInput = styled.div`
border: 3px solid rgb(235, 227, 227);
border-radius:5px;
padding: 0px 4px;
margin-bottom: 2%;
background-color: transparent;
width: 100%;
display: flex;
    flex-direction: column;
    @media only screen and (max-width: 800px) {
    }
`

export const DivForLabelInputPass = styled.div`
display: flex;
flex-direction: column;
border: 3px solid rgb(245, 245, 245);
border-radius:5px;
padding: 0px 4px;
margin-bottom: 4%;
`

export const InputProfileForm = styled.input`
    border-radius: 5px;
    border: none;
    background-color: white;
    @media only screen and (max-width: 800px) {
      border: none;
      background-color: white;

    }
     :focus  {
    
    outline:none ;
  }
`



export const ModalAddButton = styled.button`
    border-radius: 12px;
    background-color: #ff5758;
    border: none;
    padding: 5px 10px;
    margin-left: 83%;
    color: white;
    width: 18%;

    @media only screen and (max-width: 800px) {
    background-color: #ff5758;
    margin-left: 79%;
    margin-bottom: 20px;
    margin-top: 27%;
    }

`


export const ModalAddButtonUsers = styled.button`
     border-radius: 7px;
    background-color: white;
    border: 2px solid rgb(255, 87, 88);
    padding: 5px 20px;
    color: rgb(255, 87, 88);

    @media only screen and (max-width: 800px) {
    /* background-color: #ff5758; */
    /* margin-left: 79%; */
    /* margin-bottom: 20px; */
    /* margin-top: 27%; */
    }
    `
export const ModalEditButton = styled.button`
    border-radius: 7px;
    background-color: white;
    border: 2px solid rgb(255, 87, 88);
    padding: 5px 10px;
    margin-left: 77%;
    color: rgb(255, 87, 88);
    width: 19%;
    margin-top: 7%;

    @media only screen and (max-width: 800px){
    margin-bottom: 2%;

    }
`
export const FormTitle = styled.h4`
position: relative;
`

// UserEditForm//
export const EditFormTitle = styled.h4`
`
export const UserEditForm = styled.form`
   width: 100%;
  display: flex;
  justify-content: center;
 `

export const EditFormControl = styled.div`
    width: 100%;
    padding: 1% 5%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
 `

export const SelectOption = styled.select`
    border: none;
    color: rgb(231, 128, 128);
width: 100%; 
      @media only screen and (max-width: 800px) {
    width: 100%;

      }
 `
export const SelectOptionn = styled.select`
border: none;
color: rgb(231, 128, 128);
width: 100%; 
padding:  ${props => props.value === null ? '10px 0px' : "5px 0px"};

color: ${props => props.value === null ? 'lightgray' : "gray"};
font-weight: ${props => props.value === null && "600"};
font-size: ${props => props.value === null && '10px'};
.genders_options{
  padding: 5px 10px;
  font-size: ${props => props.value === null && "16px"};
  color:  ${props => props.value === null && "#3e3d3d"};
}
@media only screen and (max-width: 800px) {
    width: 100%;
}
`;
export const DivSelect = styled.div`
padding: 2%;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
    `

export const DateTime = styled.input`
border: none;
    border-radius: 6px;
    padding: 5px 4px;
    width: 100%;
    color: #e78080;
    /* background: #f5f5f5; */

  @media only screen and (max-width: 800px) {
width: 96%
  }
`
export const ModalAddButtonApp = styled.button`
border-radius: 7px;
    background-color: white;
    border: 1px solid rgb(255, 87, 88);
    padding: 5px 10px;
    color: rgb(255, 87, 88);
`

export const ButtonsModal = styled.div`
   display: flex;
    justify-content: end;
    gap: 7px;
    :focus{
      outline:none;
    }
    @media only screen and (max-width: 800px) {

    }

    @media only screen and (min-width: 1600px) {
    }
`

export const SelectOptionBox = styled.div`
/* display: flex;
flex-direction: row;
justify-content:space-around;
gap: 14px;
margin-bottom: 2%; */
    @media only screen and (max-width: 800px) {
flex-wrap: wrap;
    width: 100%;
    }
`

// input serach
export const SwitchStyle = styled.div`
position:fixed;
left:50%;
top:23px;
    @media only screen and (max-width: 800px) {
display: flex;
    /* flex-direction: column; */
    margin-top: 8%;
    margin-left: 24%;
    left: 0%;
position: absolute;
    }
`
export const ApppointmentButton = styled.button`
border-color: lightcoral;
border-radius: 12px;
background-color: white;
margin-right:5px ;
    @media only screen and (max-width: 800px) {
border-color: lightcoral;

    }
`
export const SwitchStyle2 = styled.div`
position:fixed;
left:50%;
top:23px;
    @media only screen and (max-width: 800px) {

display: flex;
    margin-top: 14%;
    margin-left: 30%;
    left: 0%;
position: absolute;
    }
`

export const SearchInputBox = styled.div`
display: flex;
    justify-content: space-evenly;
    background-color: white;
    height: 77px;
    width: 96%;
    margin-left: 2%;
    @media only screen and (max-width: 800px) {
      margin-left: 0%;
    }
`
export const SearchInput = styled.input`
border: 1px solid rgb(211, 202, 202);
position: absolute;
    border-radius: 15px;
    margin-bottom: 1%;
    padding: 5px 28px;
    margin-top: 18px;
    z-index: 9;
    width: 400px;
    color: rgb(211, 202, 202);
    @media only screen and (max-width: 800px) {
      width: 113px;
    font-size: small;
    margin-top: 16px;
    margin-left: -85px;

    }
`
export const IconsHeader = styled.div`
    margin-top: 1.6%;
     margin-bottom: 1%;
     margin-left: 71%;
     margin-right: 20px;
    display: flex;
    gap: 20px;
    @media only screen and (max-width: 800px) {
    /* margin: 5.6% 20px 6% 29%; */
    display: flex;
    gap: 10px;
    }
`
export const IconHeader = styled.div`

 `
export const InputIcone = styled.div`
  position: sticky;
  margin-left: 202px;
  transition: all 300ms ease-in-out;
  ${(props) =>
    props?.collapse
      ? css`
  background-color: white;
  position: absolute;
  left: 0;
  right: 42px;
  top: 0px;
  `
      :
      ``
  }
 @media only screen and (max-width: 800px) {
  position: sticky;
  /* margin-left: 202px; */
  transition: all 300ms ease-in-out;
  ${(props) =>
    props?.collapse
      ? css`
  background-color: white;
  position: absolute;
  left: 0;
  /* right: 42px; */
  top: 0px;
  `
      :
      ``
  }
    }
`
export const IconeI = styled.div`
 position: absolute;
  z-index:99;
  margin-top: 22px;
  margin-left: 6px;
  @media only screen and (max-width: 800px) {
    /* margin-left: -51px; */
  margin-top: 18px;

  }
`

export const PaginationStyle = styled.div`
position: fixed;
bottom: 1%;
left:50%;
transition: all 300ms ease-in-out;
${(props) =>
    props?.collapse
      ? css`
left:45%;
  `
      :
      ``
  }

@media only screen and (max-width: 800px) {
  position: initial;
margin-left:20%;


}

 `
export const SearchIcones = styled.div`
display: flex;
background-color: white;
border-bottom: 1px solid lightgray;
`


// LOGIN FORM
export const LoginLayout = styled.div`
   padding: 40px;
   background-image: url(${(props) => props.imgUrl}); 
@media only screen and (max-width: 800px) {
  padding: 22px;
}

`
export const Loginform = styled.div`


`
export const Heading = styled.h4`
`
export const FormLogin = styled.form`
   display: flex;
        flex-direction: column;
        gap: 3px;
`
export const InputEmail = styled.input`
 border: 2px solid lightgray ;
    border-radius: 2px;
    padding: 5px 10px;
    color: gray;
    background: transparent;
    font-weight: 600;
    margin-bottom:6px;
        width: 100%;
        &::placeholder{
          font-size: 11px !important;
          color: lightgray !important;
        }
`
export const InputPass = styled.input`
 border: 2px solid lightgray ;
    border-radius: 2px;
    padding: 5px 10px;
    color: gray;
    background: transparent;
    font-weight: 600;
    margin-bottom:6px;
        width: 100%;
        &::placeholder{
          font-size: 10px !important;
          color: lightgray !important;
        }
`
export const Buttons = styled.div`
display: flex;
flex-wrap: wrap;
    gap: 15px;
`
export const Paragraf = styled.p`
margin-top: 5px;
color: #FF6768;
cursor: pointer;
font-size: 10px;
  p {
  text-decoration: underline;
  color: #FF6768;
cursor: pointer;
font-size: 10px;
}
`
export const Loginbutton = styled.button`
border: none;
    border-radius: 2px;
    padding: 10px 38px;
    color: white;
    font-size: 12px;
    background: #b2b7ba;
`
export const FormLayout = styled.div`


`
export const FormRegister = styled.div`
       color: lightgray;
            display: flex;
            flex-direction: column;
            margin-right: 93px;
            gap: 10px;
    @media only screen and (max-width: 800px) {
    display: none;
    }


    @media only screen and (max-width: 800px) {
            padding: 40px;
            margin-top: -10%;
            width: 77%;
            height: 66%;
            margin-left: 8%;
    }

`
export const Text = styled.div`
display:flex;
flex-direction: column;
align-items: center;
`
export const Paragraph = styled.p`
font-size: 12px;
color: #0d870d;
    font-weight: 500;
`
export const Heading3 = styled.h3`
color: white;
`
export const Image = styled.image`
`
export const Labels = styled.div`
font-weight: 500;
`

export const ButtonRegister = styled.div`
`

export const ButtonR = styled.button`
border: none;
padding: 10px 27px;
    border-radius: 2px;
    background: #FF6768;
    color: white;
    font-size: 12px;
`

export const FormForRegister = styled.div`
display: flex;
    flex-direction: column;
    padding: 35px;
    border-radius: 28px 6px;
    background-color: white;
    position: absolute;
    z-index: 999;
    margin-top: -9.4%;
    margin-left: 5%;
    width: 37%;
    gap: 5px;
    /* max-height: 97%; */
    box-shadow: rgb(185, 181, 181) 0px 0px 9px 3px;
    @media only screen and  (min-width:1800px)  {
      margin-top: -6.4%;

    }

    @media only screen and  (max-width:800px)  {
      width: 82%;
      margin-top: -31.4%;
      padding: 25px

    }



    @media only screen and (min-width:950px) and ( max-width:1050px) {
      padding: 40px;
    margin-top: -10%;
    width: 57%;
    height: 700px;
    margin-left: 8%;
            
    }
    @media only screen and (min-width: 1920px) and (max-width: 2304px) {
      width: 30%;
            
    }
    @media only screen and (max-width: 1230px) and (min-width: 1024px) {
      display: flex;
    flex-direction: column;
    padding: 29px;
    border-radius: 28px 6px;
    background-color: white;
    position: absolute;
    z-index: 999;
    margin-top: -10%;
    margin-left: 6%;
    width: 33%;
    gap: 1px;
    max-height: 97%;
    box-shadow: rgb(185, 181, 181) 0px 0px 9px 3px;
            
    }




`

export const Register = styled.form`
 display: flex;
        flex-direction: column;
        gap: 3px;

`

export const FormRegisterLayout = styled.div`
margin-top: 12%;
display: flex;
    -webkit-box-pack: end;
    justify-content: end;
    border-radius: 5px 53px;
    padding: 50px;
    background: rgb(247 177 177);
    position: relative;
    height: 404px;

    @media only screen and (min-width:1600px)  {
      margin-top: 19%;
    }
    @media only screen and (max-width: 800px) {
            margin-top: 37%;

    }
`

export const ButtonReg = styled.button`
border: 3px solid #e1e1e1;
/* padding: 5px 45px; */
    border-radius: 2px 14px;
    background: rgb(125, 125, 125);
    color: white;
    font-weight: 600;
    padding: 4px 31px;
    :hover{
          background:transparent;
    color: gray;
    border: 3px solid #e1e1e1;

 
    transition: 0.6s;
    
  }
  :not(:hover){
  transition: 0.6s;

  }
`
export const Registerbutton = styled.button`
      border: 4px solid lightgray;
    border-radius: 5px 15px;
    padding: 5px 14px;
    color: white;
    font-weight: 600;
    background: rgb(111 211 69);
    width: 180px;
    :hover{
          background:white;
    color: gray;
    border: 3px solid rgb(25 143 25);
 
    transition: 0.6s;
    
  }
  :not(:hover){
  transition: 0.6s;

  }

  @media only screen and  (max-width:800px)  {
    height: 40px


    }
`

export const ButtonsReg = styled.div`
   display: flex;
   gap: 10px;
`

export const IconLock = styled.div`
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: relative;

`


export const RegisterbuttonAtLogin = styled.button`
      border: 4px solid lightgray;
    border-radius: 5px 15px;
    padding: 5px 14px;
    color: white;
    font-weight: 600;
    background: rgb(25 143 25);
    width: 180px;

    background:white;
    color: gray;
    border: 3px solid rgb(25 143 25);
    transition: 0.6s;

    :hover{
      background: rgb(25 143 25);

    color: white;
    border: 4px solid lightgray;
    border-radius: 5px 15px;
    transition: 0.6s;
    
  }
  :not(:hover){
  transition: 0.6s;

  }

  display: none;

  @media only screen and  (max-width:800px)  {
    height: 40px;
    margin-left: 20px;
display: block;

    }
     @media only screen and (min-width:950px) and ( max-width:1050px)  {
       height: 50px;
    margin-left: 129px;
    display: block;
    margin-top: 2%;

    }
`

export const RadioButton = styled.div`
`
export const InputRadio = styled.div`
display: flex;
gap: 10px;
`
export const RadioGroup = styled.div`
`
export const FormControlLabel = styled.label`
`

////Language
export const TableStyleLanguage = styled.div`
  border-top: 5px solid #FF5758;
  box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  border-radius: 10px;
  background-color: white;
  position: fixed;
  left: 20%;
  right: 10%;
  top: 15%;
  left: 300px;
  right: 45px;
  top: 150px;
  transition: all 300ms ease-in-out;
  ${(props) =>
    props?.collapse
      ? css`
        border-top: 5px solid #FF5758;
 box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  border-radius: 10px;
  background-color: white;
  position: fixed;
  left: 153px;
    right: 67px;
  top: 150px;

  `
      :
      ``
  }
   table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 10px 15px !important;
  }
  tr:nth-child(even) {
    background-color:#e1e5ee;
  }
  th,
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 40px;
  }
  `


export const LabelDiv = styled.div`
display: flex;
    width: 138px;
    border-radius: 14px 2px;
    border-top: 3px solid #ff5758;
    border-right: 3px solid #ff5758;
    border-left: 3px solid #ff5758;
    padding: 4px;
`

export const LanguagesForm = styled.form`
  @media only screen and (max-width: 800px) {
    background-color: white;
    border-radius: 20px;
    height: 411px
    }
 
`;

export const IconLabel = styled.div`
width: 20px;
height: 20px;
`
export const AddDiv = styled.div`
display: flex;
flex-direction: column;
align-items: end;
margin-top: 24%;
    margin-right: 4%;
`

// //// PROFILE 

export const ProfileBody = styled.div`
padding: 17px ;
display: flex;
justify-content: center;
@media only screen and (max-width: 800px) {
  padding: 0px 41px;
display: block;

}

`
export const ProfileContent = styled.div`
gap: 10px;
    border: 3px solid rgb(227, 221, 221);
    border-radius: 20px;
    display: flex;
    background: transparent;
    padding: 29px;
    justify-content: space-around;
    width: 86%;
    margin-left: 17%;
    box-shadow: rgb(223, 216, 216) 0px 0px 5px 1px;
   max-height: 956%;
@media only screen and (max-width: 800px) {
  flex-direction: column;
    margin-left: 40px;
    width: 100%;
    padding: 20px;
    gap: 10px;
    border: none;
    box-shadow: none;
}

${(props) =>
    props?.collapse
      ? css`
margin-right: 10%;
  `
      :
      ``
  }
`

export const ProfilePic = styled.div`
padding: 33px;
box-shadow: 0px 0px 5px 1px #dfd8d8;
    border-radius: 20px;
background-color: white;
display:flex ;
flex-direction: column;
align-items:center ;
max-height: 1040px;


@media only screen and (max-width: 800px) {
    width: 109%;
    margin-left: -5%;
}

`
export const ProfileText = styled.div`
padding: 20px;
box-shadow: 0px 0px 5px 1px #dfd8d8;
    border-radius: 20px;
background-color: white;
width: 50%;
    height: fit-content;
@media only screen and (max-width: 800px) {
width: 109%;
    margin-left: -4.8%;
    height: 62%;
}
`

export const ProfileImageText = styled.div`
display: flex;
justify-content: center;
font-weight: 500;
`

export const ProfileImage = styled.div`
display: flex;
justify-content: center;
`

export const ProfileButton = styled.div`
display: flex;
gap: 8px;
@media only screen and (max-width: 800px) {
/* flex-wrap: wrap; */
/* justify-content: center; */
}

`
export const InputProfile = styled.input`
border: none;
border-bottom: 1px solid gray;
`
export const ModalSaveButtonProfile = styled.button`
width: 20%;
border: none;
margin-top: 40%;
margin-left: 75%;
border-radius: 8px;
border: 1px solid #ff5758;
background: white;
color: #ff5758;
padding: 4px 66px 4px 32px;
@media only screen and (max-width: 800px) {
  width: 27%;
  margin-left: 20%;
  margin-top: 15%;
}
:hover{
box-shadow: 0px 0px 3px 1px #ff5758;
  transition: 0.6s;
}
  :not(:hover){
  transition: 0.6s;
  }
`

export const ImageProfile = styled.img`
width:373px;
@media only screen and (max-width: 800px) {
width: 103px;
}
`
export const ProfileForm = styled.form`
@media only screen and (max-width: 800px) {
  background-color: white;
    border-radius: 20px;
    height: 500px;
    margin-bottom: 10px;
}
`
//location
export const ModalAddButtonLocation = styled.button`
  border-radius: 12px;
    background-color: #ff5758;
    border: none;
    padding: 5px 10px;
    margin-left: 83%;
    color: white;
    width: 18%;
`

export const ModalSaveButton = styled.button`
 border-radius: 12px;
    background-color: #ff5758;
    border: none;
    padding: 5px 15px;
    color: white;
    margin-left: 86%;


`

export const DeleteModalButton = styled.div`
display: flex;
justify-content: space-evenly;
`

export const ModalCancelButton = styled.button`
 border-radius: 12px;
    background-color: grey;
    border: none;
    padding: 5px 15px;
    color: white;
`
export const ModalDeleteButton = styled.button`
 border-radius: 12px;
    background-color: #c53232;
    border: none;
    padding: 5px 15px;
    color: white;
`

export const ModalStyleDelete = styled.div`

position: fixed;
border-top: 3px solid  #c53232;
border-radius: 12px;
background-color: white;
z-index: 99999;
inset: 36px 20% 20% 26%;
width: 38%;
margin-left: 7%;
height: auto;
margin-top: 5%;
padding: 2%;
@media only screen and (max-width: 800px) {
    border-top: 3px solid rgb(197, 50, 50);
    background-color: white;
    z-index: 99999;
    inset: 0px 0% 0% 0%;
    width: 93%;
    margin-left: 4%;
    height: 55%;
    margin-top: 36%;
    padding: 6%;
    text-align-last: center;


}
@media only screen and (min-width: 1600px) {
    width: 32%;
    margin-left: 11%;
    margin-top: 6%;
    padding: 2%;
    height: 51%;
}
`

export const DeleteModal = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`
//profile

export const ModalActiveButtonProfile = styled.div`
      border: none;
    border-radius: 8px;
    padding: 5px;
    margin-left: 1.7%;
    width: 180px;
    margin-top: 3%;
@media only screen and (max-width: 800px) {
    width: 50%;
}
`
export const ModalStyleAppointment = styled.div`
   position: fixed;
    border-top: 6px solid rgb(255, 87, 88);
    border-radius: 12px;
    background-color: white;
    z-index: 99999;
    inset: 36px 20% 20% 26%;
    width: 35%;
    height: 525px;
    margin-left: 10%;
    /* padding: 2%; */
    margin-top: 3%;
    overflow: scroll;

    @media only screen and (min-width: 1600px) {

    height: 608px;
    }

`
export const ModalCloseButtonAppointment = styled.button`
border-bottom-left-radius: 12px;
border-top-right-radius: 12px;
background-color: rgb(255, 87, 88);
border: 0px;
padding: 5px 10px;
position: fixed;
left: 69.1%;
top: 12%;
color: white;

@media only screen and (min-width: 1600px) {
  left: 69.5%;
  top: 10.4%
}
`



export const SaveButtonProfile = styled.button`

margin-top: 0%;
    margin-bottom: 5px;
    border-radius: 8px;
    border: 1px solid rgb(255, 87, 88);
    background: rgb(217, 217, 243);
    color: white;
    padding: 5px 20px;
@media only screen and (max-width: 800px) {
 

}
:hover{
box-shadow: 0px 0px 3px 1px #ff5758;
  transition: 0.6s;
}
  :not(:hover){
  transition: 0.6s;
  }
`
export const ButtonatPorfilittt = styled.div`
display: flex;
gap: 5px;
@media only screen and (max-width: 800px) {
flex-wrap: wrap;
flex-direction: column;
}
`
export const ModalGenderButtonProfile = styled.button`
    width: 35px;
    margin-top: 2%;
    border-radius: 52px;
    border: 1px solid rgb(255, 87, 88);
    background: white;
    color: rgb(255, 87, 88);
    padding: 3px;
    .eriola{
      background-color: #ff5758;
    }
    @media only screen and (max-width: 800px) {
    margin-bottom: 2%;
}
:hover{
box-shadow: 0px 0px 3px 1px #ff5758;
  transition: 0.6s;
}
  :not(:hover){
  transition: 0.6s;
  }
    `
export const LabelProfile = styled.label`
    font-size: 12px;
    color: grey;
    `

export const LabelProfileGender = styled.label`
padding-top: 1.5%;
margin-top: 4px;
margin-left: 2%;

`


export const LabelProfileButton = styled.button`
    padding: 1px 12px;
    background: #eff2f5;
    border: 2px solid grey;
    border-radius: 10px 1px;
transition: all 300ms ease-in-out;
    :hover{
transition: all 300ms ease-in-out;
      background-color: rgb(217 217 243);
      color: white;
    }
    `

export const HeadingRole = styled.h6`
color: #9d9fa2;

`
export const Role = styled.div`
   background: transparent;
    border: 2px solid;
    border-radius: 14px;
    padding: 9px 10px 0px 10px;
    color: pink;
    margin-bottom: 10px;

`
export const NameSurname = styled.div`
/* display: flex; */
/* justify-content: space-between; */
/* gap: 40px; */
    @media only screen and (max-width: 800px) {
    }



`
export const NameFirst = styled.div`
`
export const Surname = styled.div`
`

export const FormTitleResponsive = styled.h5`
position: relative;
text-align-last: center;
font-size: large;
margin-left: 20px;
font-weight: 600;

`
export const LabelStaff = styled.label`
width: 57%;
    font-size: 15px;
    background: rgb(239 242 245);
    font-weight: 500;
    border-radius: 14px 2px;
    border-top: 3px solid rgb(255, 87, 88);
    border-right: 3px solid rgb(255, 87, 88);
    border-left: 3px solid rgb(255, 87, 88);
    padding: 4px;
    background: rgb(239 242 245);
`
export const FormControlApp = styled.form`
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  @media only screen and (max-width: 800px) {
  padding: 5%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  }
    @media only screen and (min-width: 1600px) {
    /* margin-left: 13.3%; */
  }
`
export const Salon = styled.div`
padding: 90px;
margin-left: 20%;
background-color: #f7f9fa;
${(props) =>
    props?.collapse
      ? css`
margin-left: 5%;
`
      :
      ``
  }
@media only screen and (max-width: 800px) {
padding: 5px;
margin-left: 1%;
background-color: #f7f9fa;
${(props) =>
    props?.collapse
      ? css`
margin-left: 1%;
`
      :
      ``
  }
}
`

export const ButtonGender = styled.div`
display: flex;
gap: 10px;

`

export const SaveButtonProfileGreen = styled.button`
    border:none;
    background: #34bd34;
    color: white;
    border-radius: 8px;
    padding: 5px 20px;
@media only screen and (max-width: 800px) {

}
:hover{
box-shadow: 0px 0px 3px 1px #ff5758;
  transition: 0.6s;
}
  :not(:hover){
  transition: 0.6s;
  }
`
export const Password = styled.div`
display: flex;
    justify-content: end;
    margin-top: 1%;
`
export const OldPassword = styled.label`
`
export const ProfileFormPassword = styled.form`
@media only screen and (max-width: 800px) {
  background-color: white;
    border-radius: 20px;
    /* height: 500px; */
    margin-bottom: 10px;
}

`

export const ChangePassword = styled.div`

`

export const SaveButtonDiv = styled.div`
display: flex;
justify-content: end;
margin-bottom: 2%;

`
export const SavePasswordButtonProfile = styled.button`

 margin-top: 2%;
    border-radius: 8px;
    border: 1px solid rgb(255, 87, 88);
    background: rgb(217, 217, 243);
    color: white;
    padding: 4px 20px;
@media only screen and (max-width: 800px) {
 
}
:hover{
box-shadow: 0px 0px 3px 1px #ff5758;
  transition: 0.6s;
}
  :not(:hover){
  transition: 0.6s;
  }

`

export const ButtonatSave = styled.div`
display: flex;
justify-content: end;
    /* margin-top: 4%; */
    /* margin-right: 6%; */
    @media only screen and (max-width: 800px) {
    /* margin-left: 80%; */

}
`


export const EditProfileIcon = styled.div`
display: flex;
    justify-content: end;
`

export const DivForLabelAndInput = styled.div`
    display: flex;
flex-direction: column;
border: 3px solid rgb(235 227 227);
border-radius: 5px;
/* width: 100%; */
padding: 0px 4px;
margin-bottom: 4%;
background-color: transparent;
@media only screen and (max-width: 800px) {
  width: 100%;
}
    `

export const DivForLabelAndInputLang = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(211 211 211);
    border-radius: 2px;
    padding: 0px 4px;
    width: 100%;
    background-color: transparent;


    
    @media only screen and (max-width: 800px){

    }

    
`

export const DivForLabelAndInputName = styled.div`
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(235, 227, 227);
    border-radius: 5px;
    width: 84%;
    padding: 0px 4px;
    margin-bottom: 4%;
    background-color: transparent;
    @media only screen and (max-width: 800px){
      width: 100%;
    
    }

`

export const DivForLabelInputAddress = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(235, 227, 227);
    border-radius: 5px;
    padding: 0px 4px;
    margin-bottom: 4%;
    background-color: transparent;
  @media only screen and (max-width: 800px) {
    width: 99%;

  }
`

export const DivForLabelInputClient = styled.div`
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(235, 227, 227);
    border-radius: 5px;
    padding: 0px 4px;
    margin-bottom: 4%;
    background-color: transparent;
    width: 100%;
    @media only screen and (max-width: 800px) {
      width: 99%;
  }
`
export const NameFirstAddress = styled.div`
    /* margin-left: 5%; */
  @media only screen and (max-width: 800px) {
    /* margin-left: 2%; */
  }
`
export const Warning = styled.div`
  position: fixed;
  color: red;
  margin-left:270px;
  top:75px;
  transition: all 300ms ease-in-out;
  ${(props) =>
    props?.collapse
      ? css`
  position: fixed;
  color: red;
  margin-left:100px;
  top:75px;
  transition: all 300ms ease-in-out;
`
      :
      ``
  }`


export const EditButtonSalon = styled.button`
    border-radius: 7px;
    background-color: white;
    border: 2px solid rgb(46 151 66);
    padding: 5px 10px;
    color: rgb(46 151 66);
    /* width: 19%; */

    :hover{
      color: white;
      transition: 0.6s;
      background-color: rgb(46 151 66);;
    }
    @media only screen and (max-width: 800px) {
    border-radius: 7px;
    background-color: white;
    border: 2px solid rgb(46, 151, 66);
    padding: 5px 10px;
    color: rgb(46, 151, 66);
    width: 45px;
    font-size: 13px;
    height: 33px;
    }

`

export const DeleteButtonSalon = styled.button`
    border-radius: 7px;
    background-color: white;
    border: 2px solid #c53232;
    padding: 5px 10px;
    color: #c53232;
    /* width: 19%; */

    :hover{
      transition: 0.6s;
      background-color: #c53232;
      color: white;
    }

        @media only screen and (max-width: 800px) {
    width: 60px;
    font-size: 13px;
    height: 33px;
        }
`

export const NameSurnameFirst = styled.div`

`

export const DivForLabelAndInputEmail = styled.div`
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(235, 227, 227);
    border-radius: 5px;
    /* width: 84%; */
    padding: 0px 4px;
    margin-bottom: 4%;
    background-color: transparent;
`
export const EyePass = styled.div`

`
export const TextForNothing = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-left: 70px;
`